import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClaimsHelperService } from '../../../services/claims-helper.service';

@Component({
    selector: 'is-nw-phone-number-format',
    templateUrl: './nw-phone-number-format.component.html',
    styleUrls: ['./nw-phone-number-format.component.scss'],
    standalone: false
})
export class NwPhoneNumberFormatComponent implements OnInit {
    @Input() phone;
    @Input() isCallable;
    @Input() bold = false;
    @Output() linkClick: EventEmitter<void>;
    displayNumber = '';
    isMobile = false;

    constructor (
        private claimsHelperService: ClaimsHelperService
    ) {
        this.linkClick = new EventEmitter();
    }

    ngOnInit(): any {
        this.formatNumber();
        this.isMobile = this.claimsHelperService.isMobile();
    }

    formatNumber(): void {
        if (this.phone !== null && this.phone !== undefined && this.phone !== '') {
            this.displayNumber = `1-${this.phone}`;
        }
    }

    emitClick(): void {
        this.linkClick.emit(null);
    }
}
