import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormHelper } from 'app/shared/services/form-helper.service';
import { TokenDataService } from 'app/shared/services/token-data.service';
import { UtilService } from 'app/shared/services/util.service';
import { environment } from '../../../../environments/environment';
import { FileClaimTestData } from './nw-test-constant';
import { NwTestToolService } from './nw-test-tool-service';

interface EnvironmentVariable {
    environment: string;
    serverNumber: string;
}
@Component({
    selector: 'is-nw-test-tool',
    templateUrl: './nw-test-tool.component.html',
    styleUrls: ['./nw-test-tool.component.scss'],
    standalone: false
})
export class NwTestToolComponent implements OnInit {
    /**
     * @description - nwToolBarIsDisplay; variable to show / hide the tool bar
     * @type boolean
     * @memberof NwTestToolComponent
    */
    nwToolBarIsDisplay = false;

    /**
     * @description - nwCloseCaption variable for the close button caption to the tool bar.
     * @type string
     * @memberof NwTestToolComponent
     */
    nwCloseCaption = '^';

    /**
     * @description - model - form model object to set environment variables
     * @type EnvironmentVariable
     * @memberof NwTestToolComponent
     */
    model: EnvironmentVariable = {
        environment: '',
        serverNumber: ''
    };

    awsmodel: any = {
        awsenv: ''
    };
    fileClaimTestData = FileClaimTestData;
    /**
     * @description - environmentPickForm form group displaying environment and server number as input
     * @type FormGroup
     * @memberof NwTestToolComponent
     */
    public environmentPickForm: UntypedFormGroup;
    awsenvironment: any;
    fileClaimTest: UntypedFormGroup;

    constructor (
        private fh: FormHelper,
        private utils: UtilService,
        private testToolService: NwTestToolService,
        private tokenDataService: TokenDataService) { }

    /**
     * @description - onLoad component, build the form and set the models.
     * @param none
     * @return none
     * @memberof NwTestToolComponent
     */
    ngOnInit(): any {
        this.buildForm();
        this.setModel();
    }

    /**
     * @description - toggleToolBar - show / hide the tool bar
     * @param none
     * @return none
     * @memberof NwTestToolComponent
     */
    toggleToolBar(): any {
        this.nwToolBarIsDisplay = !this.nwToolBarIsDisplay;
        this.nwCloseCaption = (this.nwCloseCaption === 'X') ? '^' : 'X';
    }

    /**
     * @description - buildForm build form and set model
     * @param none
     * @return none
     * @memberof NwTestToolComponent
     */
    buildForm(): void {
        this.setModel();
        this.environmentPickForm = this.fh.build({
            environment: [this.model.environment, []],
            serverNumber: [this.model.serverNumber, []]
        });

        this.awsenvironment = this.fh.build({
            awsenv: [this.awsmodel.awsenv]
        });

        this.fileClaimTest = this.fh.build({
            users: []
        });
    }

    /**
     * @description - setModel function to get the cookie values and set the model
     * @param none
     * @return none
     * @memberof NwTestToolComponent
     */
    setModel(): any {
        const envCookie = this.getCookiebyName(environment.claimsCookies.claimsEnv);
        const envServerNo = this.getCookiebyName(environment.claimsCookies.claimsEnvNumber);
        this.model = {
            environment: this.utils.isStrictNull(envCookie) ? environment.ClaimsEnvironment.envName : envCookie,
            serverNumber: this.utils.isStrictNull(envServerNo) ? environment.ClaimsEnvironment.serverId : envServerNo
        };

        this.awsmodel = {
            awsenv: this.getCookiebyName('aws_router')
        };
    }

    /**
     * @description - getCookiebyName - get cookie by value
     * @param name as string
     * @return cookie value as string
     * @memberof NwTestToolComponent
     */
    getCookiebyName(name): any {
        const pair = document.cookie.match(new RegExp(`${name}=([^;]+)`));
        return !!pair ? pair[1] : null;
    }

    /**
     * @description - setCookie set the cookie value read from model.
     * @param none
     * @return none
     * @memberof NwTestToolComponent
     */
    setCookie(): any {
        this.model = this.environmentPickForm.value;
        document.cookie = `${environment.claimsCookies.claimsEnv}=${this.model.environment};path=${environment.claimsCookies.path
             };domain=${environment.claimsCookies.domain}`;
        document.cookie = `${environment.claimsCookies.claimsEnvNumber
            }=${this.model.serverNumber};path=${environment.claimsCookies.path
             };domain=${environment.claimsCookies.domain}`;
        window.location.reload();
    }

    setRouter(): any {
        this.awsmodel = this.awsenvironment.value;
        this.tokenDataService.deleteCookieByName('aws_router');
        document.cookie = `aws_router=${this.awsmodel.awsenv};path=${environment.claimsCookies.path
             };domain=${environment.claimsCookies.domain}`;
        window.location.reload();
    }

    setFileClaimData(): void {
        if (this.fileClaimTest.controls.users.value !== null || this.fileClaimTest.controls.users.value !== '') {
            this.testToolService.setFileClaimData(this.fileClaimTest.controls.users.value);
        }
    }
}
