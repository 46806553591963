import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[isPhoneNumberMask]',
    standalone: false
})
export class PhoneNumberMaskDirective {
    previousCursorPosition = 0;
    previousDisplayValue = '';

    constructor (private el: ElementRef, private control: NgControl) { }

    @HostListener('keydown', ['$event'])
    @HostListener('click', ['$event'])
    @HostListener('focus', ['$event'])
    trackCursor(): any {
        this.previousCursorPosition = this.el.nativeElement.selectionStart;
    }


    @HostListener('input', ['$event'])
    onInputChange($event): any {
        let numericValue = this.el.nativeElement.value.replace(/\D/g, '');
        if (this.previousDisplayValue.replace(/\D/g, '') === numericValue &&
            this.previousDisplayValue.length < this.el.nativeElement.value) {
            numericValue = this.handleDeletedSpecialCharacter();
        }

        let displayValue;
        if (numericValue.length === 0) {
            displayValue = '';
        } else if (numericValue.length <= 3) {
            displayValue = numericValue.replace(/^(\d{0,3})/, '$1');
        } else if (numericValue.length <= 6) {
            displayValue = numericValue.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2');
        } else {
            displayValue = numericValue.replace(/^(\d{0,3})(\d{0,3})(.*)/, '$1-$2-$3');
        }
        let cursorPosition;
        if (this.previousDisplayValue.length > displayValue.length) {
            cursorPosition = this.findDeletedIndex(this.previousDisplayValue, displayValue);
        } else {
            cursorPosition = this.findDeletedIndex(displayValue, this.previousDisplayValue) + 1;
        }


        this.previousDisplayValue = displayValue;
        this.control.control.setValue(numericValue);
        $event.srcElement.value = displayValue;
        if (cursorPosition !== undefined && !Number.isNaN(cursorPosition)) {
            this.setCaretPosition(cursorPosition);
        }
    }

    handleDeletedSpecialCharacter(): any {
        const cursorPosition = this.el.nativeElement.selectionStart;
        let deleteIndex;
        if (this.previousCursorPosition !== cursorPosition) {
            // handle backspace
            for (let i = cursorPosition - 1; i >= 0; i--) {
                if (this.isDeleteable(i)) {
                    deleteIndex = i;
                    break;
                }
            }
        } else {
            // handle delete
            for (let i = cursorPosition; i < this.el.nativeElement.value.length; i++) {
                if (this.isDeleteable(i)) {
                    deleteIndex = i;
                    break;
                }
            }
        }
        return this.deleteCharacter(deleteIndex);
    }

    isDeleteable(index): any {
        return (!isNaN(parseInt(this.el.nativeElement.value[index], 10)));
    }

    deleteCharacter(index): any {
        if (index !== undefined) {
            this.el.nativeElement.value = this.el.nativeElement.value.slice(0, index) + this.el.nativeElement.value.slice(index + 1);
        }
        return this.el.nativeElement.value.replace(/\D/g, '');
    }

    findDeletedIndex(current: string, previous: string): any {
        for (let i = 0; i < current.length; i++) {
            if (current[i] !== previous[i]) {
                if (previous[i] !== undefined) {
                    return i;
                } else {
                    return current.length;
                }
            }
        }
    }

    setCaretPosition(index): any {
        this.el.nativeElement.focus();
        if (index.value) {
            this.el.nativeElement.setSelectionRange(index, index);
        }
    }
}
