import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NwInputErrorService } from 'app/shared/services/nw-input-error.service';

@Component({
    selector: 'is-nw-select',
    templateUrl: './nw-select.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    styleUrls: ['./nw-select.component.scss'],
    standalone: false
})
export class NwSelectComponent implements OnInit {
  @Input() parentGroup;

  @Input() inputFormControl;
  @Input() optionValues;
  @Input() additionalValues;
  @Input() required = true;
  requiredField: boolean;
  ariaLabel: any;
  labelValue: any;
  @Input()
  set label(value: any) {
    this.labelValue = value.replace('(Optional)', '<small>(Optional)</small>');
    this.ariaLabel = value;
  }

  get label(): any {
    return this.labelValue;
  }
  constructor(public nwInputErrorService: NwInputErrorService) { }

  ngOnInit(): void {
  }
}

