import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'is-nw-phone',
    templateUrl: './nw-phone.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    styleUrls: ['./nw-phone.component.scss'],
    standalone: false
})

export class NwPhoneComponent implements OnInit {
    @Input() parentGroup: UntypedFormGroup;
    @Output() phoneChange: EventEmitter<any> = new EventEmitter();
    phoneMask = 'isPhoneMask';
    phoneTypeValues: any = [
        { value: 'home', caption: 'Home' },
        { value: 'work', caption: 'Work' },
        { value: 'cell', caption: 'Cell' }
    ];
    constructor (
        private fb: UntypedFormBuilder
    ) { }

    ngOnInit(): any {

    }

    changeEvent(): void {
        this.phoneChange.emit();
    }
}
