<div [formGroup]='parentGroup'>

        <bolt-textfield [label]='label' [type]="type || 'text'" [iconname]="iconname" [prefixsymbol]='prefixsymbol' [suffix]='suffix'
        [attr.aria-label]="ariaLabel" [maxlength]="maxlength" [minlength]="minlength" [attr.min]="min" [attr.max]="max" (focus)="onFocus()"
        (blur)="onBlur()" (change)="onChange()" ngDefaultControl [id]="inputFormControl" [formControlName]='inputFormControl'
        *ngIf="!isPhone" [required]="required"  [optionaltext]="optionaltext"
        [attr.error]='nwInputErrorService.validateBoltTextfield(parentGroup,inputFormControl)'>
        <bolt-contextual-help  *ngIf="helpTextAttributes"
        slot="help">
        <p>{{helpTextAttributes}}</p>
        </bolt-contextual-help></bolt-textfield>

        <bolt-textfield  [label]='label' isPhoneNumberMask [type]="type || 'text'" [iconname]="iconname" [prefixsymbol]='prefixsymbol' [suffix]='suffix'
        [attr.aria-label]="ariaLabel" [maxlength]="maxlength" [minlength]="minlength" [attr.min]="min" [attr.max]="max" (focus)="onFocus()"
        (blur)="onBlur()" (change)="onChange()" ngDefaultControl [id]="inputFormControl" [formControlName]='inputFormControl'
        *ngIf="isPhone" [required]="required" [optionaltext]="optionaltext" [helptext]='helpTextAttributes'
        [attr.error]='nwInputErrorService.validateBoltTextfield(parentGroup,inputFormControl)'><bolt-contextual-help  *ngIf="helpTextAttributes"
        slot="help">
        <p>{{helpTextAttributes}}</p>
        </bolt-contextual-help></bolt-textfield>

</div>
