import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NwInputErrorService } from 'app/shared/services/nw-input-error.service';

@Component({
    selector: 'is-nw-input',
    templateUrl: './nw-input.component.html',
    styleUrls: ['./nw-input.component.scss'],
    standalone: false
})
export class NwInputComponent implements OnInit, AfterViewInit {
    @Input() placeholder = '';
    @Input() type = '';
    @Input() maxlength = 1000;
    @Input() minlength = 0;
    @Input() restrict: RegExp;
    @Input() min = 0;
    @Input() max = 1000;
    @Input() inputId = '';
    @Input() parentGroup: any = {};
    @Input() inputFormControl;
    @Input() required = true;
    @Input() helpTextAttributes: any;
    @Input() iconname = '';
    @Input() prefixsymbol = '';
    @Input() suffix = '';
    labelValue: string;
    ariaLabel: string;
    toggleHelpText = false;
    @Input() optionaltext = 'hide';
    @Input()
    set label(value: any) {
        this.labelValue = value;
        this.ariaLabel = value.replace('Ext.', 'Extension');
    }

    get label(): any {
        return this.labelValue;
    }

    @Input() isPhone = false;
    nativeControl: any;
    _value: any;
    @Output() focus: EventEmitter<NwInputComponent> = new EventEmitter();
    @Output() blur: EventEmitter<NwInputComponent> = new EventEmitter();
    @Output() change: EventEmitter<NwInputComponent> = new EventEmitter();
    constructor (private elm: ElementRef, private sanitizer: DomSanitizer, public nwInputErrorService: NwInputErrorService) { }

    ngOnInit(): void {
    }
    ngAfterViewInit(): void {
    }

    onFocus(): void {
        if (this.focus) {
            this.focus.emit();
        }
    }

    onBlur(): void {
        if (this.blur) {
            this.blur.emit();
        }
    }

    onChange(): void {
        if (this.change) {
            this.change.emit();
        }
    }
}
