<div [formGroup]="parentGroup">
  <label
    [for]='inputFormControl'
    [ngClass]='{"has-error":(parentGroup.controls[inputFormControl].invalid && parentGroup.controls[inputFormControl].touched)}'
    [innerHTML]='label'
    [id]='"label_"+inputFormControl'></label><br />
    <bolt-textarea
    ngDefaultControl
      [formControlName]='inputFormControl'
      [name]="inputFormControl"
      [attr.error]='nwInputErrorService.validateBoltTextfield(parentGroup,inputFormControl)'
      [rows]="rows"
      [id]="inputFormControl"
      [maxLength]="textMaxLength"
      [minLength]="minLength"
      [ngClass]="{'has-error':(parentGroup.controls[inputFormControl].invalid && parentGroup.controls[inputFormControl].touched)}"
      [required]='required'
      [attr.aria-required]='required'
      [attr.aria-describedby]='"label_"+inputFormControl'>
  </bolt-textarea>
  <div class="help-block" role="helptext">Characters remaining:
    <strong>{{textMaxLength - parentGroup.controls[inputFormControl].value?.length||0}}</strong>

