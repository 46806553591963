<div [formGroup]="parentGroup">
  <div class="col-sm-4  mb-4">
    <is-nw-input type="text" inputFormControl='number' [parentGroup]='parentGroup' label='Phone number' [isPhone]="true" [maxlength]="12"></is-nw-input>
  </div>
  <div class="col-sm-3  mb-4">
    <is-nw-input type="text" inputFormControl='extension'  id="extension" [maxlength]='6' [parentGroup]='parentGroup' label='Ext.' optionaltext="show" [required]="false"></is-nw-input>
  </div>
  <div class="col-sm-3  mb-4">
    <is-nw-select [optionValues]="phoneTypeValues" [parentGroup]="parentGroup" inputFormControl="type" label="Phone type" (change)="changeEvent()"></is-nw-select>
  </div>
</div>
