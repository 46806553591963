import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'is-nw-error',
    templateUrl: './nw-error.component.html',
    styleUrls: ['./nw-error.component.scss'],
    standalone: false
})
export class NwErrorComponent implements OnInit {
    @Input() inputFormControl;
    @Input() parentGroup;
    @Input() common = false;
    constructor () { }

    ngOnInit(): any {
    }
}
