import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'is-nw-incidents',
    templateUrl: './nw-incidents.component.html',
    styleUrls: ['./nw-incidents.component.scss'],
    standalone: false
})

export class NwIncidentsComponent implements OnInit {
    toggleIncidents = false;
    updateValue = 'Please Select';
    selectedIcon = '';
    incidentValue = '';
    @Input() parentGroup: UntypedFormGroup;
    @Input() inputFormControl;
    @Input() incidentDetails;
    @Output() lossCauseValue = new EventEmitter<any>();

    arrowkeyLocation = 0;

    hintText: string;
    @HostListener('document:click', ['$event', '$event.target'])
    public onClick(event: MouseEvent, targetElement: HTMLElement): void {
        if (!this.elementRef.nativeElement.contains(targetElement)) {
            if (event.target['id'] !== 'listBox') {
                this.toggleIncidents = false;
            } else {
                this.toggleIncidents = true;
            }
        }
    }
    constructor (private elementRef: ElementRef) { }

    ngOnInit(): void {
        this.valueChangeForIncident(this.parentGroup.controls[this.inputFormControl].value || '');
        this.parentGroup.controls[this.inputFormControl].valueChanges.subscribe((value) => {
            this.valueChangeForIncident(value);
        });
    }

    keyDown(event: KeyboardEvent): any {
        switch (event.keyCode) {
            case 13:
            case 9:
                this.toggleIncidentDetails();
                break;
            default:
                break;
        }
    }
    valueChangeForIncident(value): void {
        const incidentNode = this.incidentDetails.filter((incidentObject) => incidentObject.value === value);

        this.lossCauseValue.emit(incidentNode);

        this.updateValue = incidentNode[0] && incidentNode[0].caption;
        this.hintText = incidentNode[0] && incidentNode[0].hint;
        this.selectedIcon = incidentNode[0] && incidentNode[0].icon;
    }

    setOption(incident, event): any {
        this.parentGroup.controls[this.inputFormControl].setValue(incident.value);
        if (!(event.clientX === 0 && event.clientY === 0)) {
            this.toggleIncidentDetails();
        }
    }

    toggleIncidentDetails(): any {
        this.toggleIncidents = !this.toggleIncidents;
    }

    closeListBox(lastBox): any {
        if (lastBox) {
            this.toggleIncidentDetails();
        }
    }
}
