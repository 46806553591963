<bolt-notification *ngIf="!common && parentGroup.controls[inputFormControl].touched && parentGroup.controls[inputFormControl].hasError('required')">
    <span>Required</span>
</bolt-notification>
<div *ngIf="!common && !parentGroup.controls[inputFormControl].hasError('required') && inputFormControl!=='extn' && parentGroup.controls[inputFormControl].touched && parentGroup.controls[inputFormControl].hasError('pattern')"
    class="alert alert-danger nw-field-error">
    <span>Enter valid characters</span>
</div>
<div *ngIf="!common && !parentGroup.controls[inputFormControl].hasError('required') && parentGroup.controls[inputFormControl].touched && parentGroup.controls[inputFormControl].hasError('emailError')"
    class="alert alert-danger nw-field-error">
    <span>Enter valid email address</span>
</div>
<div *ngIf="!common && !parentGroup.controls[inputFormControl].hasError('required')  &&  parentGroup.controls[inputFormControl].dirty && parentGroup.controls[inputFormControl].touched && parentGroup.controls[inputFormControl].hasError('confirmFieldError')"
    class="alert alert-danger nw-field-error">
    <span>Fields do not match</span>
</div>
<bolt-notification *ngIf="!common && !parentGroup.controls[inputFormControl].hasError('required') && parentGroup.controls[inputFormControl].dirty && parentGroup.controls[inputFormControl].touched && parentGroup.controls[inputFormControl].hasError('phoneNumberError')"
    class="alert alert-danger nw-field-error">
    <span>Enter valid phone number</span>
</bolt-notification>
<div *ngIf="!common && !parentGroup.controls[inputFormControl].hasError('required') && parentGroup.controls[inputFormControl].dirty && parentGroup.controls[inputFormControl].touched && parentGroup.controls[inputFormControl].hasError('invalidRoutingNumberError')"
    class="alert alert-danger nw-field-error">
    <span>Routing number not found</span>
</div>
<div *ngIf="!common && !parentGroup.controls[inputFormControl].hasError('required') && parentGroup.controls[inputFormControl].dirty && parentGroup.controls[inputFormControl].touched && parentGroup.controls[inputFormControl].hasError('routingNumberError')"
    class="alert alert-danger nw-field-error">
    <span>Enter valid routing number</span>
</div>
<bolt-notification *ngIf="!common && !parentGroup.controls[inputFormControl].hasError('required') && parentGroup.controls[inputFormControl].hasError('checkBoxError')"
    >
        <span>Required</span>
    </bolt-notification>
<bolt-notification *ngIf="!common && !parentGroup.controls[inputFormControl].hasError('required') && inputFormControl==='extn' && parentGroup.controls[inputFormControl].touched && parentGroup.controls[inputFormControl].hasError('pattern')"
    class="alert alert-danger nw-field-error">
    <span>Enter the valid extension number</span>
</bolt-notification>
<bolt-notification *ngIf="common"
    class="col-sm-12 alert alert-danger nw-field-error">
    <span>Please check the fields!</span>
</bolt-notification>
