import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
@Component({
    selector: 'is-nw-address',
    templateUrl: './nw-address.component.html',
    styleUrls: ['./nw-address.component.scss'],
    standalone: false
})
export class NwAddressComponent implements OnInit {
    @Input() countries: any = {};
    @Input() parentGroup: UntypedFormGroup;
    addressForm: UntypedFormGroup;
    stateOptionValues: any = [];
    countryOptionValues: any = [];
    constructor () { }

    ngOnInit(): void {
        this.buildOptionValues();
    }
    @Input()
    set states(value) {
        this.stateOptionValues = [];
        value.forEach((element) => {
            this.stateOptionValues.push({
                value: element.value,
                caption: element.name
            });
        });
    }

    get states(): any {
        return this.stateOptionValues;
    }

    buildOptionValues(): void {
        this.countries.forEach((element) => {
            this.countryOptionValues.push({
                value: element.value,
                caption: element.name
            });
        });
        this.countryOptionValues.push({
            value: 'countryOther',
            caption: 'Other'
        });
    }
}
