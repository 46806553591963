import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonalConstants } from 'app/common/personalConstants';
import { CryptService } from 'app/shared/services/crypt.service';
import { SessionService } from 'app/shared/services/session.service';

/**
 * Documentation
 * @url http://localhost:4200/#/stubPar?goto={pageName}
 * @pagenames contact, memberproperty, summary, confirmation.
 * @url http://localhost:4200/#/stubPar?goto={pageName}&insured=true : to view the contact page on choosing insured pni for member.
 * @undone: currently the forms are working for member, TODO: member support
 */
@Component({
    selector: 'is-stub',
    templateUrl: './stub.component.html',
    styleUrls: ['./stub.component.scss'],
    standalone: false
})
export class StubComponent implements OnInit {
    constructor (private crypt: CryptService,
        private route: ActivatedRoute,
        private router: Router,
        private sessionService: SessionService) { }

    ngOnInit(): any {
        this.crypt.setSessionItem('contactincident', JSON.stringify(this.setContactDetails()));
        this.crypt.setSessionItem('fnolForm', JSON.stringify(this.returnFnolForm()));
        this.crypt.setSessionItem('fnol', JSON.stringify(this.returnFnol()));
        this.crypt.setSessionItem('memberpropertyinformation', JSON.stringify(this.returnMember_propertyDetails()));
        this.crypt.setSessionItem('policyAddress', JSON.stringify(this.returnPolicyAddress()));

        this.route.queryParams.subscribe((params) => {
            const goto = params['goto'] || 'firstnotice';
            const isMember = this.sessionService.isMember();
            // const type = params['type'] || 'unregistered-member-support';
            const insured = params['insured'] || 'true';
            if (isMember) {
                this.crypt.setSessionItem('contactincident', JSON.stringify(this.setContactDetails()));
                this.crypt.setSessionItem('fnolForm', JSON.stringify(this.returnFnolForm()));
                this.crypt.setSessionItem('fnol', JSON.stringify(this.returnFnol()));
                this.crypt.setSessionItem('memberpropertyinformation', JSON.stringify(this.returnMember_propertyDetails()));
                this.crypt.setSessionItem('policyAddress', JSON.stringify(this.returnPolicyAddress()));
                if (insured === 'true') {
                    this.crypt.setSessionItem('contactincident', JSON.stringify(this.returnMemberContactForm()));
                    this.crypt.setSessionItem('members', JSON.stringify(this.returnMembersForMember()));
                } else {
                    this.crypt.setSessionItem('members', JSON.stringify(this.returnMembersForMember()));
                    this.crypt.setSessionItem('contactincident', JSON.stringify(this.setContactDetails()));
                }
            } else {
                this.crypt.setSessionItem('contactincident', JSON.stringify(this.returnMemberSupportContactForm()));
                this.crypt.setSessionItem('fnolForm', JSON.stringify(this.returnFnolForm()));
                this.crypt.setSessionItem('fnol', JSON.stringify(this.returnFnol()));
                this.crypt.setSessionItem('memberpropertyinformation', JSON.stringify(this.returnMember_propertyDetails()));
                this.crypt.setSessionItem('policyAddress', JSON.stringify(this.returnPolicyAddress()));
                this.crypt.setSessionItem('members', JSON.stringify(this.returnMembersForMember()));
            }
            switch (goto) {
                case 'summary':
                    this.router.navigate([PersonalConstants.URLS.claimsSummary]);
                    break;
                case 'contact':
                    this.router.navigate([PersonalConstants.URLS.contactAndIncident]);
                    break;
                case 'memberproperty':
                    this.router.navigate([PersonalConstants.URLS.memberPropertyInformation]);
                    break;
                case 'confirmation':
                    this.crypt.setSessionItem('confirmationResponse', JSON.stringify(this.confirmationResponse()));
                    this.router.navigate([PersonalConstants.URLS.claimsConfirmation]);
                    break;
            }
        });
    }

    returnPolicyAddress(): any {
        return {
            'addressLine1': '119 MONROE AVE',
            'city': 'ST MARYS',
            'state': 'PA',
            'postalCode': '15857',
            'country': 'US',
            'county': 'ELK',
            'displayName': '119 MONROE AVE, ST MARYS, PA 15857-0000',
            'latitude': '41.42026',
            'longitude': '-78.58290',
            'hexID': 'PA047028776'
        };
    }

    setContactDetails(): any {
        return {
            'submitterEmail': 'asdf@asdf.com',
            'insuredMember': 'cc:56326692',
            'description': '32423422',
            'incidentLocation': '119 MONROE AVE, ST MARYS, PA 15857-0000',
            'phoneGroup': {
                'number': '234-234-2342',
                'extension': null,
                'type': 'home'
            },
            'incidentDetails': 'hail',
            'acknowledgement': true,
            'incs': '',
            'addressline1': '1119 MONROE RD',
            'city': 'SAINT MARYS',
            'state': 'PA',
            'zip': '15857-3235',
            'country': 'USA'
        };
    }

    setMemberDetails(): any {

    }

    returnMemberContactForm(): any {
        return {
            'submitterEmail': 'asdf@asdf.com',
            'insuredMember': 'cc:56326691',
            'textAlert': 'No',
            'description': '32423422',
            'incidentLocation': '119 MONROE AVE, ST MARYS, PA 15857-0000',
            'memberHomePhone': '324-234-2342',
            'memberCellPhone': '234-234-2342',
            'memberWorkPhone': '234-234-2342',
            'memberExtn': '2222',
            'prefered': 'home',
            'incidentDetails': 'hail',
            'acknowledgement': true,
            'incs': ''
        };
    }

    returnMemberSupportContactForm(): any {
        return {
            'submitterEmail': 'asdf@asdf.com',
            'submitterFirstName': 'asdfsdaf',
            'submitterLastName': 'asdfsdaf',
            'description': '32423422',
            'incidentLocation': '119 MONROE AVE, ST MARYS, PA 15857-0000',
            'phoneGroup': {
                'number': '234-234-2342',
                'extension': null,
                'type': 'home'
            },
            'incidentDetails': 'hail',
            'acknowledgement': true,
            'incs': ''
        };
    }

    returnMembersForMember(): any {
        return [
            {
                'publicID': 'cc:56326691',
                'displayName': 'BRETT A. CUNNINGHAM',
                'contactName': 'CUNNINGHAM, BRETT A.',
                'firstName': 'BRETT',
                'middleName': 'A.',
                'lastName': 'CUNNINGHAM',
                'subtype': 'Person',
                'primaryAddress': {
                    'publicID': 'cc:117970105',
                    'displayName': '119 MONROE AVE, SAINT MARYS, PA 15857',
                    'addressLine1': '119 MONROE AVE',
                    'city': 'SAINT MARYS',
                    'state': 'PA',
                    'postalCode': '15857',
                    'country': 'US'
                },
                'contactStatuses': [],
                'policyRole': 'insured',
                'contactType': 'Person',
                'isInsured': true,
                'eligibleForReoccurringText': true
            },
            {
                'publicID': 'cc:56326692',
                'displayName': 'HEIDI A. CUNNINGHAM',
                'contactName': 'CUNNINGHAM, HEIDI A.',
                'firstName': 'HEIDI',
                'middleName': 'A.',
                'lastName': 'CUNNINGHAM',
                'subtype': 'Person',
                'primaryAddress': {
                    'publicID': 'cc:117970101',
                    'displayName': '1119 MONROE RD, SAINT MARYS, PA 15857-3235',
                    'addressLine1': '1119 MONROE RD',
                    'city': 'SAINT MARYS',
                    'state': 'PA',
                    'postalCode': '15857-3235',
                    'country': 'US'
                },
                'contactStatuses': [],
                'policyRole': 'coveredparty',
                'contactType': 'Person',
                'isInsured': false,
                'eligibleForReoccurringText': true
            }
        ];
    }

    returnMember_propertyDetails(): any {
        return {
            'fallenTreeIndicator': 'Yes',
            'propertyInformationGroup': {
                'dwlInvolvedInd': true,
                'otherStrInvolvedInd': null,
                'contentInvolvedInd': null,
                'vehicleOrBoatInd': null,
                'otherInd': null
            },
            'contractorInvolvedInd': 'Yes',
            'homeDamageGroup': {
                'roofDamaged': true,
                'gutterDamaged': null,
                'windowsDoorsDamaged': null,
                'interiorDamages': null,
                'awningDamaged': null,
                'powerMeterDamaged': null,
                'shedDamaged': null,
                'otherDamaged': null
            },
            'extentofRoofDamage': 'missing',
            'contractorNamePhoneNumber': 'asdf/3242',
            'estimatesReceived': 'Yes',
            'estRepairCost': 324222,
            'damagedBestDescription': 'TreeFallenOntoBuilding',
            'allRepairsCompleted': 'Yes'
        };
    }

    returnFnol(): any {
        return {
            'publicID': 'cc:9028',
            'claimNumber': '005686-ZZ',
            'policy': {
                'publicID': 'cc:11449',
                'policyNumber': 'HXL 0020736397',
                'policyType': 'Homeowners',
                'policySystem': 'home',
                'underwritingGroup': 'ALLIED P&C INSURANCE COMPANY',
                'expirationDate': '2019-01-23T05:00:00Z',
                'effectiveDate': '2018-01-23T05:00:00Z',
                'nw_RateState': 'PA',
                'status': 'inforce',
                'coverages': [],
                'lobs': {
                    'homeowner': {
                        'locations': [
                            {
                                'publicID': 'cc:10202',
                                'locationNumber': '1',
                                'locationAddress': {
                                    'publicID': 'cc:117970104',
                                    'displayName': '119 MONROE AVE, ST MARYS, PA 15857-0000',
                                    'addressLine1': '119 MONROE AVE',
                                    'city': 'ST MARYS',
                                    'state': 'PA',
                                    'postalCode': '15857-0000',
                                    'country': 'US'
                                },
                                'hasListedItems': false,
                                'locationBasedRisks': [
                                    {
                                        'publicID': 'cc:16422',
                                        'rUNumber': 1,
                                        'majorPerils': [
                                            {
                                                'publicID': 'cc:11992',
                                                'deductible': '$500',
                                                'majorPerilName': 'All Other'
                                            }
                                        ],
                                        'insuredItems': [
                                            {
                                                'publicID': 'cc:34811',
                                                'insuredItemName': 'Dwelling',
                                                'limits': '$161,200',
                                                'coverageGroup': 'Dwelling'
                                            },
                                            {
                                                'publicID': 'cc:34812',
                                                'insuredItemName': 'Other Structures',
                                                'limits': '$16,120',
                                                'coverageGroup': 'Other Structures'
                                            },
                                            {
                                                'publicID': 'cc:34813',
                                                'insuredItemName': 'Contents',
                                                'limits': '$112,840',
                                                'coverageGroup': 'Contents'
                                            },
                                            {
                                                'publicID': 'cc:34814',
                                                'insuredItemName': 'Additional Living Expense',
                                                'limits': 'Selected',
                                                'coverageGroup': 'Additional Living Expense'
                                            },
                                            {
                                                'publicID': 'cc:34815',
                                                'insuredItemName': 'Liability',
                                                'limits': '$300,000',
                                                'coverageGroup': 'Liability'
                                            }
                                        ],
                                        'specialItems': [],
                                        'lienholders': [
                                            {
                                                'publicID': 'cc:4438',
                                                'lienholder': {
                                                    'publicID': 'cc:56326694',
                                                    'displayName': 'NORTHWEST SAVINGS BANK',
                                                    'contactName': 'NORTHWEST SAVINGS BANK',
                                                    'subtype': 'Company',
                                                    'primaryAddress': {
                                                        'publicID': 'cc:117970103',
                                                        'displayName': 'PO BOX 39545, SOLON, OH 44139-0000',
                                                        'addressLine1': 'PO BOX 39545',
                                                        'city': 'SOLON',
                                                        'state': 'OH',
                                                        'postalCode': '44139-0000',
                                                        'country': 'US'
                                                    },
                                                    'contactStatuses': [],
                                                    'contactType': 'Company'
                                                },
                                                'ownerType': 'nw_Mortgagee'
                                            }
                                        ],
                                        'coverages': [
                                            {
                                                'publicID': 'cc:50165',
                                                'name': 'Homeowners',
                                                'coverageGroup': 'New Coverage - Undefined',
                                                'sequenceNumber': 1,
                                                'coverageTerms': []
                                            }
                                        ]
                                    }
                                ],
                                'highValueItems': []
                            }
                        ]
                    }
                }
            },
            'lossDate': '2018-11-11T06:12:00Z',
            'claimState': 'draft',
            'reportedDate': '2019-07-02T15:04:52Z',
            'lossType': 'PR',
            'claimContacts': [
                {
                    'publicID': 'cc:27516',
                    'contactDTO': {
                        'publicID': 'cc:56326691',
                        'displayName': 'BRETT A. CUNNINGHAM',
                        'contactName': 'CUNNINGHAM, BRETT A.',
                        'firstName': 'BRETT',
                        'middleName': 'A.',
                        'lastName': 'CUNNINGHAM',
                        'subtype': 'Person',
                        'primaryAddress': {
                            'publicID': 'cc:117970105',
                            'displayName': '119 MONROE AVE, SAINT MARYS, PA 15857',
                            'addressLine1': '119 MONROE AVE',
                            'city': 'SAINT MARYS',
                            'state': 'PA',
                            'postalCode': '15857',
                            'country': 'US'
                        },
                        'contactStatuses': [],
                        'policyRole': 'insured',
                        'contactType': 'Person',
                        'isInsured': true,
                        'eligibleForReoccurringText': true
                    },
                    'contactRoles': [
                        'insured'
                    ],
                    'contactRolesDisplay': [
                        'Insured'
                    ],
                    'contactProhibited': false
                },
                {
                    'publicID': 'cc:27517',
                    'contactDTO': {
                        'publicID': 'cc:56326692',
                        'displayName': 'HEIDI A. CUNNINGHAM',
                        'contactName': 'CUNNINGHAM, HEIDI A.',
                        'firstName': 'HEIDI',
                        'middleName': 'A.',
                        'lastName': 'CUNNINGHAM',
                        'subtype': 'Person',
                        'primaryAddress': {
                            'publicID': 'cc:117970101',
                            'displayName': '1119 MONROE RD, SAINT MARYS, PA 15857-3235',
                            'addressLine1': '1119 MONROE RD',
                            'city': 'SAINT MARYS',
                            'state': 'PA',
                            'postalCode': '15857-3235',
                            'country': 'US'
                        },
                        'contactStatuses': [],
                        'policyRole': 'coveredparty',
                        'contactType': 'Person',
                        'isInsured': false,
                        'eligibleForReoccurringText': true
                    },
                    'contactRoles': [
                        'coveredparty'
                    ],
                    'contactRolesDisplay': [
                        'Covered Party'
                    ],
                    'contactProhibited': false
                },
                {
                    'publicID': 'cc:27518',
                    'contactDTO': {
                        'publicID': 'cc:56326693',
                        'displayName': 'ST MARYS INS AGENCY INC',
                        'contactName': 'ST MARYS INS AGENCY INC',
                        'subtype': 'Company',
                        'primaryAddress': {
                            'publicID': 'cc:117970102',
                            'displayName': 'PO BOX 32, ST MARYS, PA 15857-0032',
                            'addressLine1': 'PO BOX 32',
                            'city': 'ST MARYS',
                            'state': 'PA',
                            'postalCode': '15857-0032',
                            'country': 'US'
                        },
                        'contactStatuses': [],
                        'contactType': 'Company',
                        'isInsured': false
                    },
                    'contactRoles': [
                        'agent'
                    ],
                    'contactRolesDisplay': [
                        'Agent'
                    ],
                    'contactProhibited': false
                }
            ],
            'relatedContacts': [],
            'lobs': {
                'homeowners': {
                    'vehicles': [],
                    'vehicleIncidents': [],
                    'injuryIncidents': [],
                    'fixedPropertyIncidents': []
                }
            },
            'documents': [],
            'insuredInjuredOtherInd': false,
            'reportedByType': 'self',
            'fraudLanguage': {
                'state': 'PA',
                'size': 12,
                'font': 'ARIAL',
                'language': `Any person who knowingly and with intent to defraud any insurance company
                or other person files an application for insurance or statement of claim containing
                any materially false information or conceals for the purpose of misleading,
                information concerning any fact material thereto commits a fraudulent insurance act,
                which is a crime and subjects such a person to criminal and civil penalties.`
            },
            'nolEntryPoint': 'claims_self_service'
        };
    }

    confirmationResponse(): any {
        return {
            'publicID': 'cc:9028',
            'claimNumber': '005683-ID',
            'policy': {
                'publicID': 'cc:11449',
                'policyNumber': 'HXL 0020736397',
                'policyType': 'Homeowners',
                'policySystem': 'home',
                'underwritingGroup': 'ALLIED P&C INSURANCE COMPANY',
                'expirationDate': '2019-01-23T05:00:00Z',
                'effectiveDate': '2018-01-23T05:00:00Z',
                'nw_RateState': 'PA',
                'status': 'inforce',
                'coverages': [],
                'lobs': {
                    'homeowner': {
                        'locations': [
                            {
                                'publicID': 'cc:10202',
                                'locationNumber': '1',
                                'locationAddress': {
                                    'publicID': 'cc:117970104',
                                    'displayName': '119 MONROE AVE, ST MARYS, PA 15857-0000',
                                    'addressLine1': '119 MONROE AVE',
                                    'city': 'ST MARYS',
                                    'state': 'PA',
                                    'postalCode': '15857-0000',
                                    'country': 'US'
                                },
                                'hasListedItems': false,
                                'locationBasedRisks': [
                                    {
                                        'publicID': 'cc:16422',
                                        'rUNumber': 1,
                                        'majorPerils': [
                                            {
                                                'publicID': 'cc:11992',
                                                'deductible': '$500',
                                                'majorPerilName': 'All Other'
                                            }
                                        ],
                                        'insuredItems': [
                                            {
                                                'publicID': 'cc:34811',
                                                'insuredItemName': 'Dwelling',
                                                'limits': '$161,200',
                                                'coverageGroup': 'Dwelling'
                                            },
                                            {
                                                'publicID': 'cc:34812',
                                                'insuredItemName': 'Other Structures',
                                                'limits': '$16,120',
                                                'coverageGroup': 'Other Structures'
                                            },
                                            {
                                                'publicID': 'cc:34813',
                                                'insuredItemName': 'Contents',
                                                'limits': '$112,840',
                                                'coverageGroup': 'Contents'
                                            },
                                            {
                                                'publicID': 'cc:34814',
                                                'insuredItemName': 'Additional Living Expense',
                                                'limits': 'Selected',
                                                'coverageGroup': 'Additional Living Expense'
                                            },
                                            {
                                                'publicID': 'cc:34815',
                                                'insuredItemName': 'Liability',
                                                'limits': '$300,000',
                                                'coverageGroup': 'Liability'
                                            }
                                        ],
                                        'specialItems': [],
                                        'lienholders': [
                                            {
                                                'publicID': 'cc:4438',
                                                'lienholder': {
                                                    'publicID': 'cc:56326694',
                                                    'displayName': 'NORTHWEST SAVINGS BANK',
                                                    'contactName': 'NORTHWEST SAVINGS BANK',
                                                    'subtype': 'Company',
                                                    'primaryAddress': {
                                                        'publicID': 'cc:117970103',
                                                        'displayName': 'PO BOX 39545, SOLON, OH 44139-0000',
                                                        'addressLine1': 'PO BOX 39545',
                                                        'city': 'SOLON',
                                                        'state': 'OH',
                                                        'postalCode': '44139-0000',
                                                        'country': 'US'
                                                    },
                                                    'contactStatuses': [],
                                                    'contactType': 'Company'
                                                },
                                                'ownerType': 'nw_Mortgagee'
                                            }
                                        ],
                                        'coverages': [
                                            {
                                                'publicID': 'cc:50165',
                                                'name': 'Homeowners',
                                                'coverageGroup': 'New Coverage - Undefined',
                                                'sequenceNumber': 1,
                                                'coverageTerms': []
                                            }
                                        ]
                                    }
                                ],
                                'highValueItems': []
                            }
                        ]
                    }
                }
            },
            'lossDate': '2018-11-11T06:12:00Z',
            'claimState': 'draft',
            'reportedDate': '2019-07-02T15:04:52Z',
            'lossType': 'PR',
            'claimContacts': [
                {
                    'publicID': 'cc:27516',
                    'contactDTO': {
                        'publicID': 'cc:56326691',
                        'displayName': 'BRETT A. CUNNINGHAM',
                        'contactName': 'CUNNINGHAM, BRETT A.',
                        'firstName': 'BRETT',
                        'middleName': 'A.',
                        'lastName': 'CUNNINGHAM',
                        'subtype': 'Person',
                        'primaryAddress': {
                            'publicID': 'cc:117970105',
                            'displayName': '119 MONROE AVE, SAINT MARYS, PA 15857',
                            'addressLine1': '119 MONROE AVE',
                            'city': 'SAINT MARYS',
                            'state': 'PA',
                            'postalCode': '15857',
                            'country': 'US'
                        },
                        'contactStatuses': [],
                        'policyRole': 'insured',
                        'contactType': 'Person',
                        'isInsured': true,
                        'eligibleForReoccurringText': true
                    },
                    'contactRoles': [
                        'insured'
                    ],
                    'contactRolesDisplay': [
                        'Insured'
                    ],
                    'contactProhibited': false
                },
                {
                    'publicID': 'cc:27517',
                    'contactDTO': {
                        'publicID': 'cc:56326692',
                        'displayName': 'HEIDI A. CUNNINGHAM',
                        'contactName': 'CUNNINGHAM, HEIDI A.',
                        'firstName': 'HEIDI',
                        'middleName': 'A.',
                        'lastName': 'CUNNINGHAM',
                        'subtype': 'Person',
                        'primaryAddress': {
                            'publicID': 'cc:117970101',
                            'displayName': '1119 MONROE RD, SAINT MARYS, PA 15857-3235',
                            'addressLine1': '1119 MONROE RD',
                            'city': 'SAINT MARYS',
                            'state': 'PA',
                            'postalCode': '15857-3235',
                            'country': 'US'
                        },
                        'contactStatuses': [],
                        'policyRole': 'coveredparty',
                        'contactType': 'Person',
                        'isInsured': false,
                        'eligibleForReoccurringText': true
                    },
                    'contactRoles': [
                        'coveredparty'
                    ],
                    'contactRolesDisplay': [
                        'Covered Party'
                    ],
                    'contactProhibited': false
                },
                {
                    'publicID': 'cc:27518',
                    'contactDTO': {
                        'publicID': 'cc:56326693',
                        'displayName': 'ST MARYS INS AGENCY INC',
                        'contactName': 'ST MARYS INS AGENCY INC',
                        'subtype': 'Company',
                        'primaryAddress': {
                            'publicID': 'cc:117970102',
                            'displayName': 'PO BOX 32, ST MARYS, PA 15857-0032',
                            'addressLine1': 'PO BOX 32',
                            'city': 'ST MARYS',
                            'state': 'PA',
                            'postalCode': '15857-0032',
                            'country': 'US'
                        },
                        'contactStatuses': [],
                        'contactType': 'Company',
                        'isInsured': false
                    },
                    'contactRoles': [
                        'agent'
                    ],
                    'contactRolesDisplay': [
                        'Agent'
                    ],
                    'contactProhibited': false
                }
            ],
            'relatedContacts': [],
            'lobs': {
                'homeowners': {
                    'vehicles': [],
                    'vehicleIncidents': [],
                    'injuryIncidents': [],
                    'fixedPropertyIncidents': []
                }
            },
            'documents': [],
            'insuredInjuredOtherInd': false,
            'reportedByType': 'self',
            'fraudLanguage': {
                'state': 'PA',
                'size': 12,
                'font': 'ARIAL',
                'language': `Any person who knowingly and with intent to defraud any insurance company
                or other person files an application for insurance or statement of claim containing
                any materially false information or conceals for the purpose of misleading,
                information concerning any fact material thereto commits a fraudulent insurance act,
                which is a crime and subjects such a person to criminal and civil penalties.`
            },
            'nolEntryPoint': 'claims_self_service',
            'adjuster': {
                'displayName': 'test stub',
                'phoneNumber': '234-234-8393',
                'email': 'h@h.com'
            }
        };
    }

    returnFnolForm(): any {
        return {
            'startPage': {
                'applyClaim': 'No',
                'incidentHour': '01',
                'dateOfIncident': '11/11/2018',
                'incidentMinute': '12',
                'incidentMeridiem': 'AM'
            }
        };
    }

    getClaimDetails(): any {
        return {
            'claimNumber': '552406-GJ',
            'publicID': 'cc:10490793',
            'lossDate': '2019-09-06T19:20:00Z',
            'lossType': 'AUTO',
            'reportedDate': '2019-09-06T20:45:04Z',
            'lossCause': 'vehcollision',
            'lossLocation': {
                'publicID': 'cc:284800829',
                'displayName': 'Pine Forest  at Clay Road, Houston, TX 77084',
                'addressLine1': 'Pine Forest  at Clay Road',
                'city': 'Houston',
                'state': 'TX',
                'postalCode': '77084',
                'country': 'US',
                'county': 'HARRIS',
                'latitude': 29.831193,
                'longitude': -95.66165,
                'hexID': 'TX201056243'
            },
            'policeNotified': false,
            'description': 'It appeared to me the car ahead of me started moving and then I hit her right rear bumper',
            'policy': {
                'publicID': 'cc:13427915',
                'policyNumber': '7842J 066785',
                'policyType': 'auto_per',
                'policySystem': 'policycenter',
                'underwritingGroup': 'NATIONWIDE MUTUAL INSURANCE COMPANY',
                'expirationDate': '2020-01-19T05:00:00Z',
                'effectiveDate': '2019-07-19T04:00:00Z',
                'nw_RateState': 'TX',
                'status': 'inforce',
                'coverages': [],
                'lobs': {
                    'personalAuto': {
                        'vehicles': [
                            {
                                'publicID': 'cc:28859266',
                                'make': 'LINC',
                                'model': 'MKZ',
                                'odometer': 45162,
                                'unknownOdometer': false,
                                'state': 'TX',
                                'style': 'passengercar',
                                'vIN': '3LNHL2GC6CR814630',
                                'year': 2012,
                                'country': 'US',
                                'coverages': [
                                    {
                                        'publicID': 'cc:175179456',
                                        'name': 'Collision',
                                        'deductible': {
                                            'amount': 500,
                                            'currency': 'usd'
                                        },
                                        'coverageGroup': 'Collision',
                                        'sequenceNumber': 1,
                                        'coverageTerms': [
                                            {
                                                'publicID': 'cc:33577770',
                                                'description': 'Original Equipment Manufacturer (OEM)'
                                            }
                                        ]
                                    },
                                    {
                                        'publicID': 'cc:175179451',
                                        'name': 'Comprehensive',
                                        'deductible': {
                                            'amount': 500,
                                            'currency': 'usd'
                                        },
                                        'coverageGroup': 'Comprehensive',
                                        'sequenceNumber': 2,
                                        'coverageTerms': [
                                            {
                                                'publicID': 'cc:33577768',
                                                'description': 'Original Equipment Manufacturer (OEM)'
                                            }
                                        ]
                                    },
                                    {
                                        'publicID': 'cc:175179455',
                                        'name': 'Liability - Bodily Injury',
                                        'exposureLimit': {
                                            'amount': 30000,
                                            'currency': 'usd'
                                        },
                                        'incidentLimit': {
                                            'amount': 60000,
                                            'currency': 'usd'
                                        },
                                        'coverageGroup': 'Amount Only',
                                        'sequenceNumber': 3,
                                        'coverageTerms': []
                                    },
                                    {
                                        'publicID': 'cc:175179457',
                                        'name': 'Liability - Property Damage',
                                        'incidentLimit': {
                                            'amount': 25000,
                                            'currency': 'usd'
                                        },
                                        'coverageGroup': 'Amount Only',
                                        'sequenceNumber': 4,
                                        'coverageTerms': []
                                    },
                                    {
                                        'publicID': 'cc:175179452',
                                        'name': 'PIP',
                                        'exposureLimit': {
                                            'amount': 5000,
                                            'currency': 'usd'
                                        },
                                        'coverageGroup': 'Fixed Text',
                                        'sequenceNumber': 5,
                                        'coverageTerms': [
                                            {
                                                'publicID': 'cc:33577769',
                                                'description': 'PIP amount reflected above is medical limits only.'
                                            }
                                        ]
                                    },
                                    {
                                        'publicID': 'cc:175179458',
                                        'name': 'Roadside Assistance',
                                        'coverageGroup': 'Amount Only',
                                        'sequenceNumber': 6,
                                        'coverageTerms': [
                                            {
                                                'publicID': 'cc:33577771',
                                                'description': '\'Plus\' Roadside Assistance'
                                            }
                                        ]
                                    },
                                    {
                                        'publicID': 'cc:175179453',
                                        'name': 'Uninsured - Bodily Injury',
                                        'exposureLimit': {
                                            'amount': 30000,
                                            'currency': 'usd'
                                        },
                                        'incidentLimit': {
                                            'amount': 60000,
                                            'currency': 'usd'
                                        },
                                        'coverageGroup': 'Amount Only',
                                        'sequenceNumber': 7,
                                        'coverageTerms': []
                                    },
                                    {
                                        'publicID': 'cc:175179454',
                                        'name': 'Uninsured - Property Damage',
                                        'exposureLimit': {
                                            'amount': 25000,
                                            'currency': 'usd'
                                        },
                                        'coverageGroup': 'Amount Only',
                                        'sequenceNumber': 8,
                                        'coverageTerms': []
                                    }
                                ],
                                'policyVehicle': true
                            }
                        ]
                    }
                }
            },
            'addresses': [
                {
                    'publicID': 'cc:284800829',
                    'displayName': 'Pine Forest  at Clay Road, Houston, TX 77084',
                    'addressLine1': 'Pine Forest  at Clay Road',
                    'city': 'Houston',
                    'state': 'TX',
                    'postalCode': '77084',
                    'country': 'US',
                    'county': 'HARRIS',
                    'latitude': 29.831193,
                    'longitude': -95.66165,
                    'hexID': 'TX201056243'
                },
                {
                    'publicID': 'cc:284797024',
                    'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084-7802',
                    'addressLine1': '4422 PINE HOLLOW TRCE',
                    'city': 'HOUSTON',
                    'state': 'TX',
                    'postalCode': '77084-7802',
                    'country': 'US',
                    'county': 'HARRIS',
                    'latitude': 29.837806,
                    'longitude': -95.662146,
                    'hexID': 'TX201056243'
                },
                {
                    'publicID': 'cc:284797022',
                    'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084',
                    'addressLine1': '4422 PINE HOLLOW TRCE',
                    'city': 'HOUSTON',
                    'state': 'TX',
                    'postalCode': '77084',
                    'country': 'US'
                },
                {
                    'publicID': 'cc:284797021',
                    'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084',
                    'addressLine1': '4422 PINE HOLLOW TRCE',
                    'city': 'HOUSTON',
                    'state': 'TX',
                    'postalCode': '77084',
                    'country': 'US'
                },
                {
                    'publicID': 'cc:284794619',
                    'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084',
                    'addressLine1': '4422 PINE HOLLOW TRCE',
                    'city': 'HOUSTON',
                    'state': 'TX',
                    'postalCode': '77084',
                    'country': 'US'
                },
                {
                    'publicID': 'cc:284797020',
                    'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084',
                    'addressLine1': '4422 PINE HOLLOW TRCE',
                    'city': 'HOUSTON',
                    'state': 'TX',
                    'postalCode': '77084',
                    'country': 'US'
                }
            ],
            'mainContact': {
                'publicID': 'cc:122401574',
                'displayName': 'MRS. BETTY E KRAMER',
                'contactName': 'MRS. BETTY E KRAMER',
                'firstName': 'MRS. BETTY E',
                'lastName': 'KRAMER',
                'subtype': 'Person',
                'primaryAddress': {
                    'publicID': 'cc:284797024',
                    'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084-7802',
                    'addressLine1': '4422 PINE HOLLOW TRCE',
                    'city': 'HOUSTON',
                    'state': 'TX',
                    'postalCode': '77084-7802',
                    'country': 'US',
                    'county': 'HARRIS',
                    'latitude': 29.837806,
                    'longitude': -95.662146,
                    'hexID': 'TX201056243'
                },
                'primaryPhoneType': 'mobile',
                'cellNumber': '713-826-0855',
                'emailAddress1': 'bkramer350@yahoo.com',
                'contactStatuses': [
                    {
                        'publicID': 'cc:31489108',
                        'status': 'claim_closed',
                        'updatedDate': '2019-09-25T14:16:04Z',
                        'closedDate': '2019-09-25T14:16:03Z'
                    },
                    {
                        'publicID': 'cc:31084986',
                        'status': 'claim_received',
                        'updatedDate': '2019-09-09T13:22:12Z',
                        'objectCreateTime': '2019-09-06T20:45:04Z',
                        'reportedDate': '2019-09-06T20:45:04Z'
                    }
                ],
                'policyRole': 'insured',
                'contactType': 'Person',
                'isInsured': true,
                'optInTextMessage': true,
                'eligibleForReoccurringText': true
            },
            'relatedContacts': [
                {
                    'publicID': 'cc:122401574',
                    'displayName': 'MRS. BETTY E KRAMER',
                    'contactName': 'MRS. BETTY E KRAMER',
                    'firstName': 'MRS. BETTY E',
                    'lastName': 'KRAMER',
                    'subtype': 'Person',
                    'primaryAddress': {
                        'publicID': 'cc:284797024',
                        'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084-7802',
                        'addressLine1': '4422 PINE HOLLOW TRCE',
                        'city': 'HOUSTON',
                        'state': 'TX',
                        'postalCode': '77084-7802',
                        'country': 'US',
                        'county': 'HARRIS',
                        'latitude': 29.837806,
                        'longitude': -95.662146,
                        'hexID': 'TX201056243'
                    },
                    'primaryPhoneType': 'mobile',
                    'cellNumber': '713-826-0855',
                    'emailAddress1': 'bkramer350@yahoo.com',
                    'contactStatuses': [
                        {
                            'publicID': 'cc:31489108',
                            'status': 'claim_closed',
                            'updatedDate': '2019-09-25T14:16:04Z',
                            'closedDate': '2019-09-25T14:16:03Z'
                        },
                        {
                            'publicID': 'cc:31084986',
                            'status': 'claim_received',
                            'updatedDate': '2019-09-09T13:22:12Z',
                            'objectCreateTime': '2019-09-06T20:45:04Z',
                            'reportedDate': '2019-09-06T20:45:04Z'
                        }
                    ],
                    'policyRole': 'insured',
                    'contactType': 'Person',
                    'isInsured': true,
                    'optInTextMessage': true,
                    'eligibleForReoccurringText': true
                },
                {
                    'publicID': 'cc:122401578',
                    'displayName': 'BETTY E KRAMER',
                    'contactName': 'BETTY KRAMER',
                    'firstName': 'BETTY',
                    'middleName': 'E',
                    'lastName': 'KRAMER',
                    'birthDate': '1932-11-05T05:00:00Z',
                    'subtype': 'Person',
                    'driverNumber': '0005',
                    'primaryAddress': {
                        'publicID': 'cc:284797022',
                        'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084',
                        'addressLine1': '4422 PINE HOLLOW TRCE',
                        'city': 'HOUSTON',
                        'state': 'TX',
                        'postalCode': '77084',
                        'country': 'US'
                    },
                    'contactStatuses': [
                        {
                            'publicID': 'cc:31489109',
                            'status': 'claim_closed',
                            'updatedDate': '2019-09-25T14:16:04Z',
                            'closedDate': '2019-09-25T14:16:03Z'
                        },
                        {
                            'publicID': 'cc:31084987',
                            'status': 'claim_received',
                            'updatedDate': '2019-09-09T13:22:12Z',
                            'objectCreateTime': '2019-09-06T20:45:04Z',
                            'reportedDate': '2019-09-06T20:45:04Z'
                        }
                    ],
                    'contactType': 'Person',
                    'isInsured': false,
                    'eligibleForReoccurringText': false
                },
                {
                    'publicID': 'cc:122401577',
                    'displayName': 'BENJAMIN BRYANT',
                    'contactName': 'BENJAMIN BRYANT',
                    'firstName': 'BENJAMIN',
                    'lastName': 'BRYANT',
                    'birthDate': '1942-03-05T04:00:00Z',
                    'subtype': 'Person',
                    'driverNumber': '0004',
                    'primaryAddress': {
                        'publicID': 'cc:284797021',
                        'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084',
                        'addressLine1': '4422 PINE HOLLOW TRCE',
                        'city': 'HOUSTON',
                        'state': 'TX',
                        'postalCode': '77084',
                        'country': 'US'
                    },
                    'contactStatuses': [
                        {
                            'publicID': 'cc:31489110',
                            'status': 'claim_closed',
                            'updatedDate': '2019-09-25T14:16:04Z',
                            'closedDate': '2019-09-25T14:16:03Z'
                        },
                        {
                            'publicID': 'cc:31084988',
                            'status': 'claim_received',
                            'updatedDate': '2019-09-09T13:22:12Z',
                            'objectCreateTime': '2019-09-06T20:45:04Z',
                            'reportedDate': '2019-09-06T20:45:04Z'
                        }
                    ],
                    'policyRole': 'coveredparty',
                    'contactType': 'Person',
                    'isInsured': false,
                    'eligibleForReoccurringText': true
                },
                {
                    'publicID': 'cc:122401575',
                    'displayName': 'ROY KRAMER',
                    'contactName': 'ROY KRAMER',
                    'firstName': 'ROY',
                    'lastName': 'KRAMER',
                    'birthDate': '1928-09-06T04:00:00Z',
                    'subtype': 'Person',
                    'driverNumber': '0002',
                    'primaryAddress': {
                        'publicID': 'cc:284794619',
                        'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084',
                        'addressLine1': '4422 PINE HOLLOW TRCE',
                        'city': 'HOUSTON',
                        'state': 'TX',
                        'postalCode': '77084',
                        'country': 'US'
                    },
                    'contactStatuses': [
                        {
                            'publicID': 'cc:31489111',
                            'status': 'claim_closed',
                            'updatedDate': '2019-09-25T14:16:04Z',
                            'closedDate': '2019-09-25T14:16:03Z'
                        },
                        {
                            'publicID': 'cc:31084989',
                            'status': 'claim_received',
                            'updatedDate': '2019-09-09T13:22:12Z',
                            'objectCreateTime': '2019-09-06T20:45:04Z',
                            'reportedDate': '2019-09-06T20:45:04Z'
                        }
                    ],
                    'policyRole': 'coveredparty',
                    'contactType': 'Person',
                    'isInsured': false,
                    'eligibleForReoccurringText': true
                },
                {
                    'publicID': 'cc:122401579',
                    'displayName': 'ISCENTIAL INC',
                    'contactName': 'ISCENTIAL INC',
                    'lastName': 'ISCENTIAL INC',
                    'subtype': 'Person',
                    'primaryAddress': {
                        'publicID': 'cc:284797023',
                        'displayName': '8220 JONES RD, STE 100, ISCENTIAL, HOUSTON, TX 77065',
                        'addressLine1': '8220 JONES RD',
                        'addressLine2': 'STE 100',
                        'addressLine3': 'ISCENTIAL',
                        'city': 'HOUSTON',
                        'state': 'TX',
                        'postalCode': '77065',
                        'country': 'US'
                    },
                    'workNumber': '713-856-5533',
                    'contactStatuses': [],
                    'contactType': 'Person',
                    'isInsured': false,
                    'eligibleForReoccurringText': false
                },
                {
                    'publicID': 'cc:122401576',
                    'displayName': 'SHIRLEY BRYANT',
                    'contactName': 'SHIRLEY BRYANT',
                    'firstName': 'SHIRLEY',
                    'lastName': 'BRYANT',
                    'birthDate': '1961-01-18T05:00:00Z',
                    'subtype': 'Person',
                    'driverNumber': '0003',
                    'primaryAddress': {
                        'publicID': 'cc:284797020',
                        'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084',
                        'addressLine1': '4422 PINE HOLLOW TRCE',
                        'city': 'HOUSTON',
                        'state': 'TX',
                        'postalCode': '77084',
                        'country': 'US'
                    },
                    'primaryPhoneType': 'mobile',
                    'cellNumber': '713-826-0855',
                    'contactStatuses': [
                        {
                            'publicID': 'cc:31489112',
                            'status': 'claim_closed',
                            'updatedDate': '2019-09-25T14:16:04Z',
                            'closedDate': '2019-09-25T14:16:03Z'
                        },
                        {
                            'publicID': 'cc:31084990',
                            'status': 'claim_received',
                            'updatedDate': '2019-09-09T13:22:12Z',
                            'objectCreateTime': '2019-09-06T20:45:04Z',
                            'reportedDate': '2019-09-06T20:45:04Z'
                        }
                    ],
                    'policyRole': 'coveredparty',
                    'contactType': 'Person',
                    'isInsured': false,
                    'eligibleForReoccurringText': true
                }
            ],
            'claimContacts': [
                {
                    'publicID': 'cc:83724325',
                    'contactDTO': {
                        'publicID': 'cc:122401574',
                        'displayName': 'MRS. BETTY E KRAMER',
                        'contactName': 'MRS. BETTY E KRAMER',
                        'firstName': 'MRS. BETTY E',
                        'lastName': 'KRAMER',
                        'subtype': 'Person',
                        'primaryAddress': {
                            'publicID': 'cc:284797024',
                            'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084-7802',
                            'addressLine1': '4422 PINE HOLLOW TRCE',
                            'city': 'HOUSTON',
                            'state': 'TX',
                            'postalCode': '77084-7802',
                            'country': 'US',
                            'county': 'HARRIS',
                            'latitude': 29.837806,
                            'longitude': -95.662146,
                            'hexID': 'TX201056243'
                        },
                        'primaryPhoneType': 'mobile',
                        'cellNumber': '713-826-0855',
                        'emailAddress1': 'bkramer350@yahoo.com',
                        'contactStatuses': [
                            {
                                'publicID': 'cc:31489108',
                                'status': 'claim_closed',
                                'updatedDate': '2019-09-25T14:16:04Z',
                                'closedDate': '2019-09-25T14:16:03Z'
                            },
                            {
                                'publicID': 'cc:31084986',
                                'status': 'claim_received',
                                'updatedDate': '2019-09-09T13:22:12Z',
                                'objectCreateTime': '2019-09-06T20:45:04Z',
                                'reportedDate': '2019-09-06T20:45:04Z'
                            }
                        ],
                        'policyRole': 'insured',
                        'contactType': 'Person',
                        'isInsured': true,
                        'optInTextMessage': true,
                        'eligibleForReoccurringText': true
                    },
                    'contactRoles': [
                        'incidentowner',
                        'maincontact',
                        'reporter',
                        'insured'
                    ],
                    'contactRolesDisplay': [
                        'Owner',
                        'Main Contact',
                        'Reporter',
                        'Insured'
                    ],
                    'contactProhibited': false
                },
                {
                    'publicID': 'cc:83724324',
                    'contactDTO': {
                        'publicID': 'cc:122401578',
                        'displayName': 'BETTY E KRAMER',
                        'contactName': 'BETTY KRAMER',
                        'firstName': 'BETTY',
                        'middleName': 'E',
                        'lastName': 'KRAMER',
                        'birthDate': '1932-11-05T05:00:00Z',
                        'subtype': 'Person',
                        'driverNumber': '0005',
                        'primaryAddress': {
                            'publicID': 'cc:284797022',
                            'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084',
                            'addressLine1': '4422 PINE HOLLOW TRCE',
                            'city': 'HOUSTON',
                            'state': 'TX',
                            'postalCode': '77084',
                            'country': 'US'
                        },
                        'contactStatuses': [
                            {
                                'publicID': 'cc:31489109',
                                'status': 'claim_closed',
                                'updatedDate': '2019-09-25T14:16:04Z',
                                'closedDate': '2019-09-25T14:16:03Z'
                            },
                            {
                                'publicID': 'cc:31084987',
                                'status': 'claim_received',
                                'updatedDate': '2019-09-09T13:22:12Z',
                                'objectCreateTime': '2019-09-06T20:45:04Z',
                                'reportedDate': '2019-09-06T20:45:04Z'
                            }
                        ],
                        'contactType': 'Person',
                        'isInsured': false,
                        'eligibleForReoccurringText': false
                    },
                    'contactRoles': [
                        'excludedparty'
                    ],
                    'contactRolesDisplay': [
                        'Excluded Party'
                    ],
                    'contactProhibited': false
                },
                {
                    'publicID': 'cc:83724321',
                    'contactDTO': {
                        'publicID': 'cc:122401577',
                        'displayName': 'BENJAMIN BRYANT',
                        'contactName': 'BENJAMIN BRYANT',
                        'firstName': 'BENJAMIN',
                        'lastName': 'BRYANT',
                        'birthDate': '1942-03-05T04:00:00Z',
                        'subtype': 'Person',
                        'driverNumber': '0004',
                        'primaryAddress': {
                            'publicID': 'cc:284797021',
                            'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084',
                            'addressLine1': '4422 PINE HOLLOW TRCE',
                            'city': 'HOUSTON',
                            'state': 'TX',
                            'postalCode': '77084',
                            'country': 'US'
                        },
                        'contactStatuses': [
                            {
                                'publicID': 'cc:31489110',
                                'status': 'claim_closed',
                                'updatedDate': '2019-09-25T14:16:04Z',
                                'closedDate': '2019-09-25T14:16:03Z'
                            },
                            {
                                'publicID': 'cc:31084988',
                                'status': 'claim_received',
                                'updatedDate': '2019-09-09T13:22:12Z',
                                'objectCreateTime': '2019-09-06T20:45:04Z',
                                'reportedDate': '2019-09-06T20:45:04Z'
                            }
                        ],
                        'policyRole': 'coveredparty',
                        'contactType': 'Person',
                        'isInsured': false,
                        'eligibleForReoccurringText': true
                    },
                    'contactRoles': [
                        'coveredparty'
                    ],
                    'contactRolesDisplay': [
                        'Covered Party'
                    ],
                    'contactProhibited': false
                },
                {
                    'publicID': 'cc:83724320',
                    'contactDTO': {
                        'publicID': 'cc:122401575',
                        'displayName': 'ROY KRAMER',
                        'contactName': 'ROY KRAMER',
                        'firstName': 'ROY',
                        'lastName': 'KRAMER',
                        'birthDate': '1928-09-06T04:00:00Z',
                        'subtype': 'Person',
                        'driverNumber': '0002',
                        'primaryAddress': {
                            'publicID': 'cc:284794619',
                            'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084',
                            'addressLine1': '4422 PINE HOLLOW TRCE',
                            'city': 'HOUSTON',
                            'state': 'TX',
                            'postalCode': '77084',
                            'country': 'US'
                        },
                        'contactStatuses': [
                            {
                                'publicID': 'cc:31489111',
                                'status': 'claim_closed',
                                'updatedDate': '2019-09-25T14:16:04Z',
                                'closedDate': '2019-09-25T14:16:03Z'
                            },
                            {
                                'publicID': 'cc:31084989',
                                'status': 'claim_received',
                                'updatedDate': '2019-09-09T13:22:12Z',
                                'objectCreateTime': '2019-09-06T20:45:04Z',
                                'reportedDate': '2019-09-06T20:45:04Z'
                            }
                        ],
                        'policyRole': 'coveredparty',
                        'contactType': 'Person',
                        'isInsured': false,
                        'eligibleForReoccurringText': true
                    },
                    'contactRoles': [
                        'coveredparty'
                    ],
                    'contactRolesDisplay': [
                        'Covered Party'
                    ],
                    'contactProhibited': false
                },
                {
                    'publicID': 'cc:83724323',
                    'contactDTO': {
                        'publicID': 'cc:122401579',
                        'displayName': 'ISCENTIAL INC',
                        'contactName': 'ISCENTIAL INC',
                        'lastName': 'ISCENTIAL INC',
                        'subtype': 'Person',
                        'primaryAddress': {
                            'publicID': 'cc:284797023',
                            'displayName': '8220 JONES RD, STE 100, ISCENTIAL, HOUSTON, TX 77065',
                            'addressLine1': '8220 JONES RD',
                            'addressLine2': 'STE 100',
                            'addressLine3': 'ISCENTIAL',
                            'city': 'HOUSTON',
                            'state': 'TX',
                            'postalCode': '77065',
                            'country': 'US'
                        },
                        'workNumber': '713-856-5533',
                        'contactStatuses': [],
                        'contactType': 'Person',
                        'isInsured': false,
                        'eligibleForReoccurringText': false
                    },
                    'contactRoles': [
                        'agent'
                    ],
                    'contactRolesDisplay': [
                        'Agent'
                    ],
                    'contactProhibited': false
                },
                {
                    'publicID': 'cc:83724322',
                    'contactDTO': {
                        'publicID': 'cc:122401576',
                        'displayName': 'SHIRLEY BRYANT',
                        'contactName': 'SHIRLEY BRYANT',
                        'firstName': 'SHIRLEY',
                        'lastName': 'BRYANT',
                        'birthDate': '1961-01-18T05:00:00Z',
                        'subtype': 'Person',
                        'driverNumber': '0003',
                        'primaryAddress': {
                            'publicID': 'cc:284797020',
                            'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084',
                            'addressLine1': '4422 PINE HOLLOW TRCE',
                            'city': 'HOUSTON',
                            'state': 'TX',
                            'postalCode': '77084',
                            'country': 'US'
                        },
                        'primaryPhoneType': 'mobile',
                        'cellNumber': '713-826-0855',
                        'contactStatuses': [
                            {
                                'publicID': 'cc:31489112',
                                'status': 'claim_closed',
                                'updatedDate': '2019-09-25T14:16:04Z',
                                'closedDate': '2019-09-25T14:16:03Z'
                            },
                            {
                                'publicID': 'cc:31084990',
                                'status': 'claim_received',
                                'updatedDate': '2019-09-09T13:22:12Z',
                                'objectCreateTime': '2019-09-06T20:45:04Z',
                                'reportedDate': '2019-09-06T20:45:04Z'
                            }
                        ],
                        'policyRole': 'coveredparty',
                        'contactType': 'Person',
                        'isInsured': false,
                        'eligibleForReoccurringText': true
                    },
                    'contactRoles': [
                        'driver',
                        'claimant',
                        'coveredparty'
                    ],
                    'contactRolesDisplay': [
                        'Driver',
                        'Claimant',
                        'Covered Party'
                    ],
                    'contactProhibited': false
                }
            ],
            'vendors': [],
            'claimState': 'closed',
            'claimReporter': {
                'reportedBy': {
                    'publicID': 'cc:122401574',
                    'displayName': 'MRS. BETTY E KRAMER',
                    'contactName': 'MRS. BETTY E KRAMER',
                    'firstName': 'MRS. BETTY E',
                    'lastName': 'KRAMER',
                    'subtype': 'Person',
                    'primaryAddress': {
                        'publicID': 'cc:284797024',
                        'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084-7802',
                        'addressLine1': '4422 PINE HOLLOW TRCE',
                        'city': 'HOUSTON',
                        'state': 'TX',
                        'postalCode': '77084-7802',
                        'country': 'US',
                        'county': 'HARRIS',
                        'latitude': 29.837806,
                        'longitude': -95.662146,
                        'hexID': 'TX201056243'
                    },
                    'primaryPhoneType': 'mobile',
                    'cellNumber': '713-826-0855',
                    'emailAddress1': 'bkramer350@yahoo.com',
                    'contactType': 'Person',
                    'optInTextMessage': true,
                    'eligibleForReoccurringText': true
                },
                'relationToInsured': 'self'
            },
            'legacyClaimNumber': '7842066785J 2019090605',
            'adjuster': {
                'publicID': 'user:10767',
                'displayName': 'Nationwide Claims Service',
                'firstName': 'Nationwide Claims',
                'lastName': 'Service',
                'phoneNumber': '877-777-2022'
            },
            'exposures': [
                {
                    'publicID': 'cc:16264263',
                    'coverageType': 'COLL',
                    'exposureState': 'closed',
                    'claimant': {
                        'publicID': 'cc:122401576',
                        'displayName': 'SHIRLEY BRYANT',
                        'contactName': 'SHIRLEY BRYANT',
                        'firstName': 'SHIRLEY',
                        'lastName': 'BRYANT',
                        'birthDate': '1961-01-18T05:00:00Z',
                        'subtype': 'Person',
                        'driverNumber': '0003',
                        'primaryAddress': {
                            'publicID': 'cc:284797020',
                            'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084',
                            'addressLine1': '4422 PINE HOLLOW TRCE',
                            'city': 'HOUSTON',
                            'state': 'TX',
                            'postalCode': '77084',
                            'country': 'US'
                        },
                        'primaryPhoneType': 'mobile',
                        'cellNumber': '713-826-0855',
                        'contactStatuses': [
                            {
                                'publicID': 'cc:31489112',
                                'status': 'claim_closed',
                                'updatedDate': '2019-09-25T14:16:04Z',
                                'closedDate': '2019-09-25T14:16:03Z'
                            },
                            {
                                'publicID': 'cc:31084990',
                                'status': 'claim_received',
                                'updatedDate': '2019-09-09T13:22:12Z',
                                'objectCreateTime': '2019-09-06T20:45:04Z',
                                'reportedDate': '2019-09-06T20:45:04Z'
                            }
                        ],
                        'contactType': 'Person',
                        'eligibleForReoccurringText': true
                    },
                    'exposureAdjuster': {
                        'publicID': 'cc:222918',
                        'displayName': 'Matthew Danford',
                        'firstName': 'Matthew',
                        'lastName': 'Danford',
                        'email': 'DANFORM1@nationwide.com',
                        'phoneNumber': '515-508-3939',
                        'phoneFax': '855-674-3316'
                    },
                    'lobs': {
                        'personalAuto': {
                            'vehicleIncident': {
                                'publicID': 'cc:16247244',
                                'driver': {
                                    'publicID': 'cc:122401576',
                                    'displayName': 'SHIRLEY BRYANT',
                                    'contactName': 'SHIRLEY BRYANT',
                                    'firstName': 'SHIRLEY',
                                    'lastName': 'BRYANT',
                                    'birthDate': '1961-01-18T05:00:00Z',
                                    'subtype': 'Person',
                                    'driverNumber': '0003',
                                    'primaryAddress': {
                                        'publicID': 'cc:284797020',
                                        'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084',
                                        'addressLine1': '4422 PINE HOLLOW TRCE',
                                        'city': 'HOUSTON',
                                        'state': 'TX',
                                        'postalCode': '77084',
                                        'country': 'US'
                                    },
                                    'primaryPhoneType': 'mobile',
                                    'cellNumber': '713-826-0855',
                                    'contactStatuses': [
                                        {
                                            'publicID': 'cc:31489112',
                                            'status': 'claim_closed',
                                            'updatedDate': '2019-09-25T14:16:04Z',
                                            'closedDate': '2019-09-25T14:16:03Z'
                                        },
                                        {
                                            'publicID': 'cc:31084990',
                                            'status': 'claim_received',
                                            'updatedDate': '2019-09-09T13:22:12Z',
                                            'objectCreateTime': '2019-09-06T20:45:04Z',
                                            'reportedDate': '2019-09-06T20:45:04Z'
                                        }
                                    ],
                                    'policyRole': 'coveredparty',
                                    'contactType': 'Person',
                                    'isInsured': false,
                                    'eligibleForReoccurringText': true
                                },
                                'driverRelation': 'relativewithinhousehold',
                                'passengers': [],
                                'numberOfPassengers': 1,
                                'vehicleType': 'listed',
                                'vehicle': {
                                    'publicID': 'cc:28859266',
                                    'make': 'LINC',
                                    'model': 'MKZ',
                                    'odometer': 45162,
                                    'unknownOdometer': false,
                                    'state': 'TX',
                                    'style': 'passengercar',
                                    'vIN': '3LNHL2GC6CR814630',
                                    'year': 2012,
                                    'country': 'US',
                                    'policyVehicle': true
                                },
                                'damageDescription': 'apron of bumper cracked/paint transfer',
                                'safeToDrive': true,
                                'unknownSafeToDrive': false,
                                'airbagsDeployed': false,
                                'unknownAirbagsDeploy': false,
                                'totalLoss': false,
                                'vehicleTowed': false,
                                'rentalRequired': false,
                                'collision': true,
                                'unknownVehicleDamage': false,
                                'collisionPoint': 'front',
                                'lossParty': 'insured',
                                'vehicleParked': false,
                                'unknownVehicleParked': false,
                                'unoccupied': false,
                                'vehicleLocation': {
                                    'publicID': 'cc:284800830',
                                    'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084-7802',
                                    'addressLine1': '4422 PINE HOLLOW TRCE',
                                    'city': 'HOUSTON',
                                    'state': 'TX',
                                    'postalCode': '77084-7802',
                                    'country': 'US',
                                    'county': 'HARRIS',
                                    'latitude': 29.837806,
                                    'longitude': -95.662146,
                                    'hexID': 'TX201056243'
                                },
                                'incidentOwner': {
                                    'publicID': 'cc:122401574',
                                    'displayName': 'MRS. BETTY E KRAMER',
                                    'contactName': 'MRS. BETTY E KRAMER',
                                    'firstName': 'MRS. BETTY E',
                                    'lastName': 'KRAMER',
                                    'subtype': 'Person',
                                    'primaryAddress': {
                                        'publicID': 'cc:284797024',
                                        'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084-7802',
                                        'addressLine1': '4422 PINE HOLLOW TRCE',
                                        'city': 'HOUSTON',
                                        'state': 'TX',
                                        'postalCode': '77084-7802',
                                        'country': 'US',
                                        'county': 'HARRIS',
                                        'latitude': 29.837806,
                                        'longitude': -95.662146,
                                        'hexID': 'TX201056243'
                                    },
                                    'primaryPhoneType': 'mobile',
                                    'cellNumber': '713-826-0855',
                                    'emailAddress1': 'bkramer350@yahoo.com',
                                    'contactStatuses': [
                                        {
                                            'publicID': 'cc:31489108',
                                            'status': 'claim_closed',
                                            'updatedDate': '2019-09-25T14:16:04Z',
                                            'closedDate': '2019-09-25T14:16:03Z'
                                        },
                                        {
                                            'publicID': 'cc:31084986',
                                            'status': 'claim_received',
                                            'updatedDate': '2019-09-09T13:22:12Z',
                                            'objectCreateTime': '2019-09-06T20:45:04Z',
                                            'reportedDate': '2019-09-06T20:45:04Z'
                                        }
                                    ],
                                    'policyRole': 'insured',
                                    'contactType': 'Person',
                                    'isInsured': true,
                                    'optInTextMessage': true,
                                    'eligibleForReoccurringText': true
                                },
                                'vehicleDamagePoints': [
                                    '00'
                                ]
                            }
                        }
                    },
                    'exposureType': 'VehicleDamage'
                }
            ],
            'checks': [],
            'lobs': {
                'personalAuto': {
                    'vehicles': [
                        {
                            'publicID': 'cc:28859266',
                            'make': 'LINC',
                            'model': 'MKZ',
                            'odometer': 45162,
                            'unknownOdometer': false,
                            'state': 'TX',
                            'style': 'passengercar',
                            'vIN': '3LNHL2GC6CR814630',
                            'year': 2012,
                            'country': 'US',
                            'policyVehicle': true
                        }
                    ],
                    'vehicleIncidents': [
                        {
                            'publicID': 'cc:16247244',
                            'driver': {
                                'publicID': 'cc:122401576',
                                'displayName': 'Unknown BRYANT',
                                'contactName': 'Unknown Unknown',
                                'firstName': 'Unknown',
                                'lastName': 'Unknown',
                                'birthDate': '1961-01-18T05:00:00Z',
                                'subtype': 'Person',
                                'driverNumber': '0003',
                                'primaryAddress': {
                                    'publicID': 'cc:284797020',
                                    'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084',
                                    'addressLine1': '4422 PINE HOLLOW TRCE',
                                    'city': 'HOUSTON',
                                    'state': 'TX',
                                    'postalCode': '77084',
                                    'country': 'US'
                                },
                                'primaryPhoneType': 'mobile',
                                'cellNumber': '713-826-0855',
                                'contactStatuses': [
                                    {
                                        'publicID': 'cc:31489112',
                                        'status': 'claim_closed',
                                        'updatedDate': '2019-09-25T14:16:04Z',
                                        'closedDate': '2019-09-25T14:16:03Z'
                                    },
                                    {
                                        'publicID': 'cc:31084990',
                                        'status': 'claim_received',
                                        'updatedDate': '2019-09-09T13:22:12Z',
                                        'objectCreateTime': '2019-09-06T20:45:04Z',
                                        'reportedDate': '2019-09-06T20:45:04Z'
                                    }
                                ],
                                'policyRole': 'coveredparty',
                                'contactType': 'Person',
                                'isInsured': false,
                                'eligibleForReoccurringText': true
                            },
                            'driverRelation': 'relativewithinhousehold',
                            'passengers': [],
                            'numberOfPassengers': 1,
                            'vehicleType': 'listed',
                            'vehicle': {
                                'publicID': 'cc:28850266',
                                'make': 'FORD',
                                'model': 'TAURUS',
                                'odometer': 45162,
                                'unknownOdometer': false,
                                'state': 'TX',
                                'style': 'passengercar',
                                'vIN': '3LNHL2GC6CR814630',
                                'year': 2016,
                                'country': 'US',
                                'policyVehicle': true
                            },
                            'damageDescription': 'apron of bumper cracked/paint transfer',
                            'safeToDrive': true,
                            'unknownSafeToDrive': false,
                            'airbagsDeployed': false,
                            'unknownAirbagsDeploy': false,
                            'totalLoss': false,
                            'vehicleTowed': false,
                            'rentalRequired': false,
                            'collision': true,
                            'unknownVehicleDamage': false,
                            'collisionPoint': 'front',
                            'lossParty': 'insured',
                            'vehicleParked': false,
                            'unknownVehicleParked': false,
                            'unoccupied': false,
                            'vehicleLocation': {
                                'publicID': 'cc:284800830',
                                'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084-7802',
                                'addressLine1': '4422 PINE HOLLOW TRCE',
                                'city': 'HOUSTON',
                                'state': 'TX',
                                'postalCode': '77084-7802',
                                'country': 'US',
                                'county': 'HARRIS',
                                'latitude': 29.837806,
                                'longitude': -95.662146,
                                'hexID': 'TX201056243'
                            },
                            'incidentOwner': {
                                'publicID': 'cc:122401574',
                                'displayName': 'MRS. BETTY E KRAMER',
                                'contactName': 'MRS. BETTY E KRAMER',
                                'firstName': 'MRS. BETTY E',
                                'lastName': 'KRAMER',
                                'subtype': 'Person',
                                'primaryAddress': {
                                    'publicID': 'cc:284797024',
                                    'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084-7802',
                                    'addressLine1': '4422 PINE HOLLOW TRCE',
                                    'city': 'HOUSTON',
                                    'state': 'TX',
                                    'postalCode': '77084-7802',
                                    'country': 'US',
                                    'county': 'HARRIS',
                                    'latitude': 29.837806,
                                    'longitude': -95.662146,
                                    'hexID': 'TX201056243'
                                },
                                'primaryPhoneType': 'mobile',
                                'cellNumber': '713-826-0855',
                                'emailAddress1': 'bkramer350@yahoo.com',
                                'contactStatuses': [
                                    {
                                        'publicID': 'cc:31489108',
                                        'status': 'claim_closed',
                                        'updatedDate': '2019-09-25T14:16:04Z',
                                        'closedDate': '2019-09-25T14:16:03Z'
                                    },
                                    {
                                        'publicID': 'cc:31084986',
                                        'status': 'claim_received',
                                        'updatedDate': '2019-09-09T13:22:12Z',
                                        'objectCreateTime': '2019-09-06T20:45:04Z',
                                        'reportedDate': '2019-09-06T20:45:04Z'
                                    }
                                ],
                                'policyRole': 'insured',
                                'contactType': 'Person',
                                'isInsured': true,
                                'optInTextMessage': true,
                                'eligibleForReoccurringText': true
                            },
                            'vehicleDamagePoints': [
                                '00'
                            ]
                        },
                        {
                            'publicID': 'cc:16247244',
                            'driver': {
                                'publicID': 'cc:122401576',
                                'displayName': 'Unoccupied Unoccupied',
                                'contactName': 'Unoccupied Unoccupied',
                                'firstName': 'Unoccupied',
                                'lastName': 'Unoccupied',
                                'birthDate': '1961-01-18T05:00:00Z',
                                'subtype': 'Person',
                                'driverNumber': '0003',
                                'primaryAddress': {
                                    'publicID': 'cc:284797020',
                                    'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084',
                                    'addressLine1': '4422 PINE HOLLOW TRCE',
                                    'city': 'HOUSTON',
                                    'state': 'TX',
                                    'postalCode': '77084',
                                    'country': 'US'
                                },
                                'primaryPhoneType': 'mobile',
                                'cellNumber': '713-826-0855',
                                'contactStatuses': [
                                    {
                                        'publicID': 'cc:31489112',
                                        'status': 'claim_closed',
                                        'updatedDate': '2019-09-25T14:16:04Z',
                                        'closedDate': '2019-09-25T14:16:03Z'
                                    },
                                    {
                                        'publicID': 'cc:31084990',
                                        'status': 'claim_received',
                                        'updatedDate': '2019-09-09T13:22:12Z',
                                        'objectCreateTime': '2019-09-06T20:45:04Z',
                                        'reportedDate': '2019-09-06T20:45:04Z'
                                    }
                                ],
                                'policyRole': 'coveredparty',
                                'contactType': 'Person',
                                'isInsured': false,
                                'eligibleForReoccurringText': true
                            },
                            'driverRelation': 'relativewithinhousehold',
                            'passengers': [],
                            'numberOfPassengers': 1,
                            'vehicleType': 'listed',
                            'vehicle': {
                                'publicID': 'cc:28559266',
                                'make': 'TOYOTA',
                                'model': 'CRAPOLLA',
                                'odometer': 45162,
                                'unknownOdometer': false,
                                'state': 'TX',
                                'style': 'passengercar',
                                'vIN': '3LNHL2GC6CR814630',
                                'year': 2019,
                                'country': 'US',
                                'policyVehicle': true
                            },
                            'damageDescription': 'apron of bumper cracked/paint transfer',
                            'safeToDrive': true,
                            'unknownSafeToDrive': false,
                            'airbagsDeployed': false,
                            'unknownAirbagsDeploy': false,
                            'totalLoss': false,
                            'vehicleTowed': false,
                            'rentalRequired': false,
                            'collision': true,
                            'unknownVehicleDamage': false,
                            'collisionPoint': 'front',
                            'lossParty': 'insured',
                            'vehicleParked': false,
                            'unknownVehicleParked': false,
                            'unoccupied': false,
                            'vehicleLocation': {
                                'publicID': 'cc:284800830',
                                'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084-7802',
                                'addressLine1': '4422 PINE HOLLOW TRCE',
                                'city': 'HOUSTON',
                                'state': 'TX',
                                'postalCode': '77084-7802',
                                'country': 'US',
                                'county': 'HARRIS',
                                'latitude': 29.837806,
                                'longitude': -95.662146,
                                'hexID': 'TX201056243'
                            },
                            'incidentOwner': {
                                'publicID': 'cc:122401574',
                                'displayName': 'MRS. BETTY E KRAMER',
                                'contactName': 'MRS. BETTY E KRAMER',
                                'firstName': 'MRS. BETTY E',
                                'lastName': 'KRAMER',
                                'subtype': 'Person',
                                'primaryAddress': {
                                    'publicID': 'cc:284797024',
                                    'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084-7802',
                                    'addressLine1': '4422 PINE HOLLOW TRCE',
                                    'city': 'HOUSTON',
                                    'state': 'TX',
                                    'postalCode': '77084-7802',
                                    'country': 'US',
                                    'county': 'HARRIS',
                                    'latitude': 29.837806,
                                    'longitude': -95.662146,
                                    'hexID': 'TX201056243'
                                },
                                'primaryPhoneType': 'mobile',
                                'cellNumber': '713-826-0855',
                                'emailAddress1': 'bkramer350@yahoo.com',
                                'contactStatuses': [
                                    {
                                        'publicID': 'cc:31489108',
                                        'status': 'claim_closed',
                                        'updatedDate': '2019-09-25T14:16:04Z',
                                        'closedDate': '2019-09-25T14:16:03Z'
                                    },
                                    {
                                        'publicID': 'cc:31084986',
                                        'status': 'claim_received',
                                        'updatedDate': '2019-09-09T13:22:12Z',
                                        'objectCreateTime': '2019-09-06T20:45:04Z',
                                        'reportedDate': '2019-09-06T20:45:04Z'
                                    }
                                ],
                                'policyRole': 'insured',
                                'contactType': 'Person',
                                'isInsured': true,
                                'optInTextMessage': true,
                                'eligibleForReoccurringText': true
                            },
                            'vehicleDamagePoints': [
                                '00'
                            ]
                        },
                        {
                            'publicID': 'cc:16247244',
                            'driver': {
                                'publicID': 'cc:122401576',
                                'displayName': 'SHIRLEY BRYANT',
                                'contactName': 'SHIRLEY BRYANT',
                                'firstName': 'SHIRLEY',
                                'lastName': 'BRYANT',
                                'birthDate': '1961-01-18T05:00:00Z',
                                'subtype': 'Person',
                                'driverNumber': '0003',
                                'primaryAddress': {
                                    'publicID': 'cc:284797020',
                                    'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084',
                                    'addressLine1': '4422 PINE HOLLOW TRCE',
                                    'city': 'HOUSTON',
                                    'state': 'TX',
                                    'postalCode': '77084',
                                    'country': 'US'
                                },
                                'primaryPhoneType': 'mobile',
                                'cellNumber': '713-826-0855',
                                'contactStatuses': [
                                    {
                                        'publicID': 'cc:31489112',
                                        'status': 'claim_closed',
                                        'updatedDate': '2019-09-25T14:16:04Z',
                                        'closedDate': '2019-09-25T14:16:03Z'
                                    },
                                    {
                                        'publicID': 'cc:31084990',
                                        'status': 'claim_received',
                                        'updatedDate': '2019-09-09T13:22:12Z',
                                        'objectCreateTime': '2019-09-06T20:45:04Z',
                                        'reportedDate': '2019-09-06T20:45:04Z'
                                    }
                                ],
                                'policyRole': 'coveredparty',
                                'contactType': 'Person',
                                'isInsured': false,
                                'eligibleForReoccurringText': true
                            },
                            'driverRelation': 'relativewithinhousehold',
                            'passengers': [],
                            'numberOfPassengers': 1,
                            'vehicleType': 'listed',
                            'vehicle': {
                                'publicID': 'cc:28859266',
                                'make': 'LINC',
                                'model': 'MKZ',
                                'odometer': 45162,
                                'unknownOdometer': false,
                                'state': 'TX',
                                'style': 'passengercar',
                                'vIN': '3LNHL2GC6CR814630',
                                'year': 2012,
                                'country': 'US',
                                'policyVehicle': true
                            },
                            'damageDescription': 'apron of bumper cracked/paint transfer',
                            'safeToDrive': true,
                            'unknownSafeToDrive': false,
                            'airbagsDeployed': false,
                            'unknownAirbagsDeploy': false,
                            'totalLoss': false,
                            'vehicleTowed': false,
                            'rentalRequired': false,
                            'collision': true,
                            'unknownVehicleDamage': false,
                            'collisionPoint': 'front',
                            'lossParty': 'insured',
                            'vehicleParked': false,
                            'unknownVehicleParked': false,
                            'unoccupied': false,
                            'vehicleLocation': {
                                'publicID': 'cc:284800830',
                                'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084-7802',
                                'addressLine1': '4422 PINE HOLLOW TRCE',
                                'city': 'HOUSTON',
                                'state': 'TX',
                                'postalCode': '77084-7802',
                                'country': 'US',
                                'county': 'HARRIS',
                                'latitude': 29.837806,
                                'longitude': -95.662146,
                                'hexID': 'TX201056243'
                            },
                            'incidentOwner': {
                                'publicID': 'cc:122401574',
                                'displayName': 'MRS. BETTY E KRAMER',
                                'contactName': 'MRS. BETTY E KRAMER',
                                'firstName': 'MRS. BETTY E',
                                'lastName': 'KRAMER',
                                'subtype': 'Person',
                                'primaryAddress': {
                                    'publicID': 'cc:284797024',
                                    'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084-7802',
                                    'addressLine1': '4422 PINE HOLLOW TRCE',
                                    'city': 'HOUSTON',
                                    'state': 'TX',
                                    'postalCode': '77084-7802',
                                    'country': 'US',
                                    'county': 'HARRIS',
                                    'latitude': 29.837806,
                                    'longitude': -95.662146,
                                    'hexID': 'TX201056243'
                                },
                                'primaryPhoneType': 'mobile',
                                'cellNumber': '713-826-0855',
                                'emailAddress1': 'bkramer350@yahoo.com',
                                'contactStatuses': [
                                    {
                                        'publicID': 'cc:31489108',
                                        'status': 'claim_closed',
                                        'updatedDate': '2019-09-25T14:16:04Z',
                                        'closedDate': '2019-09-25T14:16:03Z'
                                    },
                                    {
                                        'publicID': 'cc:31084986',
                                        'status': 'claim_received',
                                        'updatedDate': '2019-09-09T13:22:12Z',
                                        'objectCreateTime': '2019-09-06T20:45:04Z',
                                        'reportedDate': '2019-09-06T20:45:04Z'
                                    }
                                ],
                                'policyRole': 'insured',
                                'contactType': 'Person',
                                'isInsured': true,
                                'optInTextMessage': true,
                                'eligibleForReoccurringText': true
                            },
                            'vehicleDamagePoints': [
                                '00'
                            ]
                        },
                        {
                            'publicID': 'cc:16247245',
                            'driver': {
                                'publicID': 'cc:122401576',
                                'displayName': 'YOURNOT SERIOUS',
                                'contactName': 'YOURNOT SERIOUS',
                                'firstName': 'YOURNOT',
                                'lastName': 'SERIOUS',
                                'birthDate': '1961-01-18T05:00:00Z',
                                'subtype': 'Person',
                                'driverNumber': '0004',
                                'primaryAddress': {
                                    'publicID': 'cc:284797020',
                                    'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084',
                                    'addressLine1': '4422 PINE HOLLOW TRCE',
                                    'city': 'HOUSTON',
                                    'state': 'TX',
                                    'postalCode': '77084',
                                    'country': 'US'
                                },
                                'primaryPhoneType': 'mobile',
                                'cellNumber': '713-826-0855',
                                'contactStatuses': [
                                    {
                                        'publicID': 'cc:31489112',
                                        'status': 'claim_closed',
                                        'updatedDate': '2019-09-25T14:16:04Z',
                                        'closedDate': '2019-09-25T14:16:03Z'
                                    },
                                    {
                                        'publicID': 'cc:31084990',
                                        'status': 'claim_received',
                                        'updatedDate': '2019-09-09T13:22:12Z',
                                        'objectCreateTime': '2019-09-06T20:45:04Z',
                                        'reportedDate': '2019-09-06T20:45:04Z'
                                    }
                                ],
                                'policyRole': 'coveredparty',
                                'contactType': 'Person',
                                'isInsured': false,
                                'eligibleForReoccurringText': true
                            },
                            'driverRelation': 'relativewithinhousehold',
                            'passengers': [],
                            'numberOfPassengers': 1,
                            'vehicleType': 'listed',
                            'vehicle': {
                                'publicID': 'cc:28859267',
                                'make': 'MAZ',
                                'model': 'MIA',
                                'odometer': 45555,
                                'unknownOdometer': false,
                                'state': 'TX',
                                'style': 'passengercar',
                                'vIN': '3LNHL2GC6CR814630',
                                'year': 1999,
                                'country': 'US',
                                'policyVehicle': true
                            },
                            'damageDescription': 'apron of bumper cracked/paint transfer',
                            'safeToDrive': true,
                            'unknownSafeToDrive': false,
                            'airbagsDeployed': false,
                            'unknownAirbagsDeploy': false,
                            'totalLoss': false,
                            'vehicleTowed': false,
                            'rentalRequired': false,
                            'collision': true,
                            'unknownVehicleDamage': false,
                            'collisionPoint': 'front',
                            'lossParty': 'insured',
                            'vehicleParked': false,
                            'unknownVehicleParked': false,
                            'unoccupied': false,
                            'vehicleLocation': {
                                'publicID': 'cc:284800830',
                                'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084-7802',
                                'addressLine1': '4422 PINE HOLLOW TRCE',
                                'city': 'HOUSTON',
                                'state': 'TX',
                                'postalCode': '77084-7802',
                                'country': 'US',
                                'county': 'HARRIS',
                                'latitude': 29.837806,
                                'longitude': -95.662146,
                                'hexID': 'TX201056243'
                            },
                            'incidentOwner': {
                                'publicID': 'cc:122401574',
                                'displayName': 'MRS. BETTY E KRAMER',
                                'contactName': 'MRS. BETTY E KRAMER',
                                'firstName': 'MRS. BETTY E',
                                'lastName': 'KRAMER',
                                'subtype': 'Person',
                                'primaryAddress': {
                                    'publicID': 'cc:284797024',
                                    'displayName': '4422 PINE HOLLOW TRCE, HOUSTON, TX 77084-7802',
                                    'addressLine1': '4422 PINE HOLLOW TRCE',
                                    'city': 'HOUSTON',
                                    'state': 'TX',
                                    'postalCode': '77084-7802',
                                    'country': 'US',
                                    'county': 'HARRIS',
                                    'latitude': 29.837806,
                                    'longitude': -95.662146,
                                    'hexID': 'TX201056243'
                                },
                                'primaryPhoneType': 'mobile',
                                'cellNumber': '713-826-0855',
                                'emailAddress1': 'bkramer350@yahoo.com',
                                'contactStatuses': [
                                    {
                                        'publicID': 'cc:31489108',
                                        'status': 'claim_closed',
                                        'updatedDate': '2019-09-25T14:16:04Z',
                                        'closedDate': '2019-09-25T14:16:03Z'
                                    },
                                    {
                                        'publicID': 'cc:31084986',
                                        'status': 'claim_received',
                                        'updatedDate': '2019-09-09T13:22:12Z',
                                        'objectCreateTime': '2019-09-06T20:45:04Z',
                                        'reportedDate': '2019-09-06T20:45:04Z'
                                    }
                                ],
                                'policyRole': 'insured',
                                'contactType': 'Person',
                                'isInsured': true,
                                'optInTextMessage': true,
                                'eligibleForReoccurringText': true
                            },
                            'vehicleDamagePoints': [
                                '00'
                            ]
                        }
                    ],
                    'injuryIncidents': [],
                    'fixedPropertyIncidents': []
                }
            },
            'notes': [],
            'documents': []
        };
    }
}
