<div [formGroup] = 'parentGroup'>
  <div  data-toggle="buttons" [id]="'radio_'+inputFormControl">
    <bolt-radio-group [attr.label]="label" required ngDefaultControl  [name]="inputFormControl"
    [formControlName]="inputFormControl"  [attr.error]='nwInputErrorService.validateBoltTextfield(parentGroup,inputFormControl)' horizontal>
    <bolt-radio value="Yes" [id]="inputFormControl+'_yes'" [checked]="parentGroup.controls[inputFormControl]?.value ==='Yes'">Yes </bolt-radio>
    <bolt-radio value="No" [id]="inputFormControl+'_no'" [checked]="parentGroup.controls[inputFormControl]?.value ==='No'" >No</bolt-radio>
    <bolt-radio *ngIf='showIdontKnow' value="IDK" [id]="inputFormControl+'_idk'" [checked]="parentGroup.controls[inputFormControl]?.value ==='IDK'" name="inputFormControl"  >I don't know</bolt-radio>
    </bolt-radio-group>
    </div>

</div>
