import { AfterViewInit, Component } from '@angular/core';
import { ClaimsChatDataService } from 'app/shared/services/claims-chat-data.service';
import { LoggerService } from 'app/shared/services/logger.service';
import { SessionService } from 'app/shared/services/session.service';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { PersonalConstants } from '../../../common/personalConstants';

declare let window: any;
@Component({
    selector: 'is-claims-live-chat',
    templateUrl: './claims-live-chat.component.html',
    styleUrls: ['./claims-live-chat.component.scss'],
    standalone: false
})
export class ClaimsLiveChatComponent implements AfterViewInit {
    eligibleForLiveChat: boolean;
    c2cConfig;
    c2chat;
    c2chatHours;
    c2cStats;
    title = 'c2c-test';

    constructor (
        private loggerService: LoggerService,
        private claimsChatDataService: ClaimsChatDataService,
        private sessionService: SessionService,
        private cookieService: CookieService
    ) { }

    ngAfterViewInit(mockWindowForUnitTesting?: any): void {
        this.c2cConfig = environment.c2cConfig;
        this.c2cConfig.widget.info.values = this.claimsChatDataService.outputClaimsInfoValues();
        if (mockWindowForUnitTesting) {
            this.c2chatHours = mockWindowForUnitTesting.hours;
        } else if (window.nw_c2c?.hours) {
            this.c2chatHours = window.nw_c2c.hours(this.c2cConfig.hours);
        } else {
            return;
        }
        this.eligibleForLiveChat = this.shouldShowLiveChat();
        if (this.eligibleForLiveChat) {
            this.showLiveChatWindow();
        }
    }

    removeLiveChat(): void {
        try {
            if (this.c2chat && !this.c2chat?.is.existing()) {
                const widgetSession = this.c2chat.getSessionService();
                widgetSession.remove();
            }
        } catch (error) {
            this.loggerService.error('Claims EFNOL live chat error with destroying', error);
        }
    }

    showLiveChatWindow(): void {
        try {
            if (this.chatWindowExists()) {
                return;
            }
            if (!this.c2chat) {
                this.c2chat = window.nw_c2c?.chat(this.c2cConfig.widget);
            }
            if (!this.c2cStats) {
                this.c2cStats = window.nw_c2c?.stats(this.c2cConfig.widget.env);
            }
            if (this.c2chat?.is.existing()) {
                this.c2chat.restore();
                this.logEfnolBanner();
            } else if (this.c2chat) {
                if (this.c2chatHours?.isNowWithinBusinessHours()) {
                    this.c2cStats.init();
                    this.c2cStats.doIfEnoughAgentsAvailable(() => {
                        this.c2chat.create();
                        this.logEfnolBanner();
                    }, () => {
                        this.c2chat.ui.hide();
                    });
                } else {
                    this.c2chat.ui.hide();
                }
            }
        } catch (error) {
            if (this.c2chat?.ui) {
                this.c2chat.ui.hide();
            }
            this.loggerService.error('Claims EFNOL error showing live chat', error);
        }
    }

    chatWindowExists(): boolean {
        return !!document.querySelector('#c2chat');
    }

    logEfnolBanner(): void {
        const cookieNwcsaProdPersisted = this.cookieService.get('nwcsaprodpersisted');
        const cookieNwcsaProdSn = this.cookieService.get('nwcsaprodsn');
        const cookieNwcsaProdSession = this.cookieService.get('nwcsaprodsession');
        const payload = {
            url: window.location.href,
            claimType: 'Auto',
            userType: this.sessionService.getUserType(),
            cookieNwcsaProdPersisted: cookieNwcsaProdPersisted,
            cookieNwcsaProdSn: cookieNwcsaProdSn,
            cookieNwcsaProdSession: cookieNwcsaProdSession
        };
        this.loggerService.info('Claims EFNOL Banner was displayed', payload);
    }

    shouldShowLiveChat(): boolean {
        const user = this.sessionService.getUserType();
        const userType = PersonalConstants.REGISTRATION_TYPE;

        return (user === userType.REGISTEREDMEMBER || user === userType.UNREGISTEREDMEMBER || user === userType.UNREGISTEREDMEMBERSUPPORT) &&
            environment.claimsAutoEfnolLiveChatFlag;
    }
}
