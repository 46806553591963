import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'preferredContact',
    standalone: false
})
export class PreferredContactPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value === 'PostalMail') {
            return 'Postal Mail';
        } else {
            return value;
        }
    }
}
