import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NwInputErrorService } from 'app/shared/services/nw-input-error.service';

@Component({
    selector: 'is-nw-textarea',
    templateUrl: './nw-textarea.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    styleUrls: ['./nw-textarea.component.scss'],
    standalone: false
})
export class NwTextareaComponent implements OnInit {
    @Input() parentGroup;
    @Input() textMaxLength;
    @Input() minLength = 3;
    @Input() rows;
    @Input() inputFormControl;
    @Input() required = true;
    labelValue: any;
    ariaLabel: any;
    requiredField: boolean;

    @Input()
    set label(value: any) {
        this.labelValue = value.replace('(Optional)', '<small>(Optional)</small>');
        this.ariaLabel = value;
    }

    get label(): any {
        return this.labelValue;
    }
    constructor (public nwInputErrorService: NwInputErrorService) { }

    ngOnInit(): any {
    }
}
