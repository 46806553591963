import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NwInputErrorService } from 'app/shared/services/nw-input-error.service';


@Component({
    selector: 'is-nw-yesno',
    templateUrl: './nw-yesno.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    styleUrls: ['./nw-yesno.component.scss'],
    standalone: false
})
export class NwYesnoComponent implements OnInit {
  @Input() parentGroup: UntypedFormGroup;
  @Input() showIdontKnow = false;
  @Input() inputFormControl;
  @Input() required = true;
  labelValue: any;
  ariaLabel: any;

  @Input()
  set label (value: any) {
      this.labelValue = value.replace('(Optional)', '<small>(Optional)</small>');
      this.ariaLabel = value;
  }

  get label (): any {
      return this.labelValue;
  }

  constructor(private cdr: ChangeDetectorRef, public nwInputErrorService: NwInputErrorService) { }

  ngOnInit(): void {}
}
