<div [formGroup]="parentGroup" *ngIf="parentGroup">
    <div class="row form-fieldset">
        <div class="col-sm-5">
            <is-nw-select [optionValues]="countryOptionValues" inputFormControl="country" [parentGroup]="parentGroup"
                label="Country"></is-nw-select>
        </div>
    </div>
    <div class="row form-fieldset">
        <div class="col-sm-5">
            <is-nw-input type="text" name="addressline1" inputFormControl="addressline1" [parentGroup]="parentGroup"
                aria-required="true" aria-describedby="addressline1" label="Address line 1"></is-nw-input>
        </div>
    </div>
    <div class="row form-fieldset">
        <div class="col-sm-5  mb-4">
            <is-nw-input type="text" name="addressline2" inputFormControl="addressline2"
                placeholder="Ex: Apt, Suite, Floor No." [parentGroup]="parentGroup" aria-describedby="addressline2"
                label="Address line 2" optionaltext="show" [required]="false"></is-nw-input>
        </div>
    </div>
    <div class="row form-fieldset">
        <div class="col-sm-5  mb-4">
            <is-nw-input type="text" name="city" inputFormControl="city" [parentGroup]="parentGroup"
                aria-required="true" aria-describedby="city" label="City"></is-nw-input>
        </div>
        <div class="col-sm-3 mb-4" *ngIf="parentGroup.get('state').enabled">
            <is-nw-select [parentGroup]='parentGroup' inputFormControl="state" [optionValues]="states" label="State">
            </is-nw-select>
        </div>
        <div class="col-sm-3">
            <is-nw-input type="text" name="zip" inputFormControl="zip" [parentGroup]="parentGroup" aria-required="true"
                aria-describedby="zip" label="Zip"></is-nw-input>
        </div>
    </div>
    <div class="row form-fieldset">
        <div class="col-sm-3">
            <is-nw-input *ngIf="parentGroup.get('countryOther').enabled" type="text" name="countryOther"
                inputFormControl="countryOther" [parentGroup]="parentGroup" aria-required="true"
                aria-describedby="countryOther" label="Country"></is-nw-input>
        </div>
    </div>
</div>
