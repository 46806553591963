import { Component, OnInit, Renderer2 } from '@angular/core';
import { IframeQueue } from 'app/shared/services/iframe-queue.service';

@Component({
    selector: 'is-oauth-iframe',
    templateUrl: './oauth-iframe-wrapper.component.html',
    styleUrls: ['./oauth-iframe-wrapper.component.scss'],
    standalone: false
})
export class OauthIframeWrapperComponent implements OnInit {
    iframeUrl = '';
    timeoutId = null;

    constructor (
        private iframeQueue: IframeQueue,
        private renderer: Renderer2
    ) { }

    ngOnInit(): any {
        this.iframeQueue.in.subscribe((nextUrl) => {
            this.iframeUrl = nextUrl;
            this.timeboxIframeLoad();
        });

        this.renderer.listen('window', 'message', (event) => {
            if (event?.data?.type === 'EUA2_AUTHORIZE_RESPONSE') {
                this.iframeQueue.out.next(event.data.payload);
                this.iframeUrl = '';
            }
        });
    }

    private timeboxIframeLoad(): void {
        if (this.timeoutId) {
            clearInterval(this.timeoutId);
            this.timeoutId = null;
        }

        this.timeoutId = setTimeout(() => {
            if (this.iframeUrl) {
                this.iframeUrl = '';
                this.iframeQueue.out.next('CALL_TIMEOUT');
            }
            this.timeoutId = null;
        }, 15000);
    }
}
