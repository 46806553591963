<div [formGroup]="parentGroup" class="incident-details">
  <a href="javascript:void(0)" [ngClass]="{'link-arrow-blue-big-down':!toggleIncidents,  'link-arrow-blue-big-up':toggleIncidents}" class="selectBar link-arrow-blue-big-up" role="button" ngDefaultControl aria-haspopup="true" (click)="toggleIncidentDetails()" aria-selected="true">
    <div class="{{selectedIcon}} option-icon" *ngIf="updateValue" id="optionSelectedIncidentDetails"></div>
    <div [ngClass] = "{'noValue': !updateValue, 'option-value': updateValue}" id="optionSelectedIncidentDetails">{{(updateValue)?updateValue:'Please select'}}<span class="hint-text" *ngIf="hintText!==''" role="textbox">{{hintText}}</span> </div>
  </a>
  <ul aria-expanded="true" *ngIf="toggleIncidents" role="listbox">
    <li class="list-box"  *ngFor="let incidents of incidentDetails; let i = last; let index=index">
    <label [for]="inputFormControl"  [ngClass]="{'active':(incidents.value === parentGroup.controls[inputFormControl].value )}"aria-selected="true"
          [title]='incidents.hint' [attr.aria-label]="'select, '+ incidents.caption" (blur)='closeListBox(i)'  (click)="setOption(incidents,$event)" (keydown)="keyDown($event)">
        <div class="{{incidents.icon + ' icon-incidents'}}"></div>
        <div class="incident-text">{{incidents.caption}}<br/><span class="hint-text" *ngIf="incidents.hint!==''">{{incidents.hint}}</span></div>
        <input type="radio" style="margin:0; opacity: 0;position: absolute;top: 0;bottom: 0;" [name]="inputFormControl" [formControlName] = "inputFormControl" name="options" [value]="incidents.value" [id]="'incidentDetail_'+index"/>
    </label>
    </li>
  </ul>
</div>
